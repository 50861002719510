import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  IconButton,
  GlobalStyles,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, RemoveCircle, Add, List, Folder, ExpandMore, Functions, Edit } from '@mui/icons-material';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeItem2Content, TreeItem2IconContainer, TreeItem2GroupTransition, TreeItem2Label, TreeItem2Root } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TreeItem2Props, RichTreeViewProSlotProps } from '@mui/x-tree-view-pro';
import { NodeEditModal, NodeEditOptions } from './NodeEditModal';

const VALID_PARENT_CHILDREN: Record<string, string[]> = {
  'TabAxis': ['Codeframe', 'Filter', 'Function', 'Spacer'],
  'Codeframe': ['Code', 'Base'],
  'Filter': ['Codeframe', 'Function'],
};

const MAX_LEVEL_BY_TYPE: Record<string, number> = {
  'TabAxis': 0,
  'Codeframe': 2,
  'Code': 3,
  'Filter': 1,
  'Function': 2,
  'Spacer': 1,
  'Base': 2,
};

interface ExtendedGenNode {
  type: string;
  id: string;
  parentId: string | null;
  isExpanded: boolean;
  isFolder: boolean;
  value1: string | null;
  value2: string | null;
  meta: { key: string; value: string }[];
  level: number;
  anyChildren: boolean;
  children?: ExtendedGenNode[];
  name: string | null;
  isParentInfo?: boolean;
  isPC?: boolean;
  isBase?: boolean;
}

interface DragData {
  type: string;
  id: string;
  value1?: string;
  value2?: string;
  parentId?: string;
  sourceTree: 'variable' | 'node';
}

interface DropPosition {
  parentId: string | null;
  index: number;
}

interface NodeTreeEditorProps {
  initialItems: ExtendedGenNode[];
  onItemsChange: (items: ExtendedGenNode[]) => void;
  onExternalDrop?: (data: DragData, position: DropPosition) => void;
  isLoading?: boolean;
}

interface VariableDropData {
  nodeId: string;
  treeType: 'variable';
  node: ExtendedGenNode; // Add the full node data to the drag payload
}

interface DragPayload {
  nodeId: string;
  treeType: 'variable';
  nodeType: string;
  value1: string | null;
  value2: string | null;
  parentId: string | null;
  meta: { key: string; value: string }[];
  isExpanded: boolean;
  isFolder: boolean;
  level: number;
  anyChildren: boolean;
  name: string | null;
  children?: ExtendedGenNode[];
}

const getNodeIcon = (node: ExtendedGenNode | undefined) => {
  if (!node) return null;

  const hasPC = node.meta?.some(m => m.key === 'PC');
  const iconName = hasPC ? `Node${node.type}PC` : `Node${node.type}`;
  const iconPath = `/assets/icons/${iconName}.png`;

  return (
    <Box 
      component="img"
      src={iconPath}
      alt={node.type}
      sx={{ 
        width: 20,
        height: 20,
        mr: 1,
        objectFit: 'contain'
      }}
    />
  );
};

interface CustomTreeItemProps extends TreeItem2Props {
  node?: ExtendedGenNode;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

const CustomTreeItem = React.forwardRef<HTMLLIElement, CustomTreeItemProps>(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const { id, itemId, label, disabled, children, node, onDelete, onEdit, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
  } = useTreeItem2({ 
    id, 
    itemId, 
    children, 
    label, 
    disabled, 
    rootRef: ref 
  });

  const rootProps = getRootProps(other as object);
  const { draggable, onDragStart, onDragOver, onDragEnd, ...otherRootProps } = rootProps;

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (onDragStart && typeof onDragStart === 'function') {
      onDragStart(event);
      event.dataTransfer.setDragImage(
        (event.target as HTMLElement).parentElement!,
        0,
        0,
      );
    }
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onEdit && node) {
      onEdit(node.id);
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDelete && node) {
      onDelete(node.id);
    }
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...otherRootProps}>
        <TreeItem2Content 
          {...getContentProps()}
          sx={{
            display: 'flex !important',
            alignItems: 'center !important',
            p: '4px 0',
            pl: `${(node?.level || 0) * 24}px`,
            position: 'relative',
            '&:hover .action-buttons': {
              opacity: 1,
              visibility: 'visible',
            }
          }}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2IconContainer
            draggable={draggable}
            onDragStart={handleDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            sx={{ 
              cursor: 'grab',
              '&:active': { cursor: 'grabbing' }
            }}
          >
            <DragIndicatorIcon />
          </TreeItem2IconContainer>
          {getNodeIcon(node)}
          <TreeItem2Label {...getLabelProps()} />
          <Box 
            className="action-buttons"
            sx={{ 
              display: 'flex',
              position: 'absolute',
              right: 8,
              opacity: 0,
              visibility: 'hidden',
              transition: 'all 0.2s ease',
              bgcolor: 'background.paper',
              zIndex: 1
            }}
          >
            <Tooltip title="Edit" placement="top">
              <IconButton
                size="small"
                onClick={handleEdit}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  mr: 0.5
                }}
              >
                <Edit sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove" placement="top">
              <IconButton
                size="small"
                onClick={handleDelete}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <RemoveCircle sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

const getAllNodeIds = (nodes: ExtendedGenNode[]): string[] => {
  let ids: string[] = [];
  nodes.forEach(node => {
    ids.push(node.id);
    if (node.children) {
      ids = [...ids, ...getAllNodeIds(node.children)];
    }
  });
  return ids;
};

const NodeTreeEditor: React.FC<NodeTreeEditorProps> = ({ 
  initialItems, 
  onItemsChange,
  onExternalDrop,
  isLoading = false
}) => {
  const [items, setItems] = useState<ExtendedGenNode[]>(initialItems);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [dragOverError, setDragOverError] = useState<string | null>(null);
  const [dragOverNodeId, setDragOverNodeId] = useState<string | null>(null);
  const [editingNode, setEditingNode] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState<string | null>(null);

  const validateNode = useCallback((node: ExtendedGenNode, parentNode?: ExtendedGenNode): boolean => {
    const errors: Record<string, string> = {};
    
    // Validate level constraints
    const maxLevel = MAX_LEVEL_BY_TYPE[node.type];
    if (maxLevel !== undefined && node.level > maxLevel) {
      errors[node.id] = `${node.type} cannot appear at level ${node.level} (max: ${maxLevel})`;
    }

    // Validate parent-child relationships
    if (parentNode) {
      const validChildren = VALID_PARENT_CHILDREN[parentNode.type];
      if (validChildren && !validChildren.includes(node.type)) {
        errors[node.id] = `${node.type} cannot be a child of ${parentNode.type}`;
      }
    }

    setValidationErrors(prev => ({ ...prev, ...errors }));
    return Object.keys(errors).length === 0;
  }, []);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const findParentNode = (nodes: ExtendedGenNode[], childId: string): ExtendedGenNode | undefined => {
    for (const node of nodes) {
      if (node.children?.some(child => child.id === childId)) {
        return node;
      }
      if (node.children) {
        const found = findParentNode(node.children, childId);
        if (found) return found;
      }
    }
    return undefined;
  };

  const moveItem = (
    nodes: ExtendedGenNode[], 
    sourceId: string | null, 
    targetId: string | null,
    newNode?: ExtendedGenNode
  ): ExtendedGenNode[] => {
    const result = [...nodes];
    
    // If we're adding a new node
    if (sourceId === null && newNode) {
      if (targetId === null) {
        // Add to root
        result.push(newNode);
      } else {
        // Add to specific parent
        const addToParent = (items: ExtendedGenNode[]): boolean => {
          for (const item of items) {
            if (item.id === targetId) {
              item.children = item.children || [];
              item.children.push(newNode);
              item.anyChildren = true;
              return true;
            }
            if (item.children && addToParent(item.children)) {
              return true;
            }
          }
          return false;
        };
        addToParent(result);
      }
      return result;
    }

    // If we're moving an existing node
    let nodeToMove: ExtendedGenNode | undefined;
    
    // Remove node from its current position
    const removeNode = (items: ExtendedGenNode[]): ExtendedGenNode[] => {
      return items.filter(item => {
        if (item.id === sourceId) {
          nodeToMove = { ...item };
          return false;
        }
        if (item.children) {
          item.children = removeNode(item.children);
          item.anyChildren = item.children.length > 0;
        }
        return true;
      });
    };

    const newItems = removeNode(result);
    
    if (!nodeToMove) return result;
    
    // Add node to new position
    if (targetId === null) {
      newItems.push(nodeToMove);
    } else {
      const addToParent = (items: ExtendedGenNode[]): boolean => {
        for (const item of items) {
          if (item.id === targetId) {
            item.children = item.children || [];
            item.children.push(nodeToMove!);
            item.anyChildren = true;
            return true;
          }
          if (item.children && addToParent(item.children)) {
            return true;
          }
        }
        return false;
      };
      addToParent(newItems);
    }
    
    return newItems;
  };

  const handleNodeChange = useCallback((nodeId: string, updates: Partial<ExtendedGenNode>) => {
    setItems(prevItems => {
      const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.map(node => {
          if (node.id === nodeId) {
            const updatedNode = { ...node, ...updates };
            // Find parent node for validation
            const parentNode = findParentNode(prevItems, nodeId);
            validateNode(updatedNode, parentNode);
            return updatedNode;
          }
          if (node.children) {
            return { ...node, children: updateNode(node.children) };
          }
          return node;
        });

      const newItems = updateNode(prevItems);
      onItemsChange(newItems);
      return newItems;
    });
  }, [onItemsChange, validateNode]);

  const handleAddChild = useCallback((parentId: string) => {
    setItems(prevItems => {
      const addChildToNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.map(node => {
          if (node.id === parentId) {
            const validChildTypes = VALID_PARENT_CHILDREN[node.type] || [];
            const defaultChildType = validChildTypes[0] || 'Code';
            
            const newChild: ExtendedGenNode = {
              type: defaultChildType,
              id: `new-${Date.now()}`,
              parentId: node.id,
              isExpanded: false,
              isFolder: false,
              value1: null,
              value2: null,
              meta: [],
              level: node.level + 1,
              anyChildren: false,
              name: `New ${defaultChildType}`,
            };

            validateNode(newChild, node);
            
            return {
              ...node,
              children: node.children ? [...node.children, newChild] : [newChild],
              anyChildren: true,
            };
          }
          if (node.children) {
            return { ...node, children: addChildToNode(node.children) };
          }
          return node;
        });

      const newItems = addChildToNode(prevItems);
      onItemsChange(newItems);
      return newItems;
    });
  }, [onItemsChange, validateNode]);

  const handleDeleteNode = useCallback((nodeId: string) => {
    setNodeToDelete(nodeId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDelete = useCallback(() => {
    if (!nodeToDelete) return;

    setItems(prevItems => {
      const deleteNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.filter(node => {
          if (node.id === nodeToDelete) {
            return false;
          }
          if (node.children) {
            node.children = deleteNode(node.children);
          }
          return true;
        });

      const newItems = deleteNode(prevItems);
      onItemsChange(newItems);
      return newItems;
    });

    setDeleteDialogOpen(false);
    setNodeToDelete(null);
  }, [nodeToDelete, onItemsChange]);

  const handleItemMove = useCallback((
    itemId: string,
    oldPosition: { parentId: string | null, index: number },
    newPosition: { parentId: string | null, index: number }
  ) => {
    setItems(prevItems => {
      // Deep clone the items to avoid mutation
      const clonedItems = JSON.parse(JSON.stringify(prevItems));
      
      // Find and remove the item from its old position
      const removeItem = (nodes: ExtendedGenNode[], parentId: string | null): [ExtendedGenNode[], ExtendedGenNode | null] => {
        if (parentId === oldPosition.parentId) {
          const [removedItem] = nodes.splice(oldPosition.index, 1);
          return [nodes, removedItem];
        }
        
        for (let i = 0; i < nodes.length; i++) {
          const children = nodes[i].children || [];
          if (children.length > 0) {
            const [newChildren, removedItem] = removeItem(children, nodes[i].id);
            nodes[i].children = newChildren;
            if (removedItem) return [nodes, removedItem];
          }
        }
        return [nodes, null];
      };

      // Insert the item at its new position
      const insertItem = (nodes: ExtendedGenNode[], parentId: string | null, item: ExtendedGenNode): ExtendedGenNode[] => {
        if (parentId === newPosition.parentId) {
          nodes.splice(newPosition.index, 0, item);
          return nodes;
        }
        
        return nodes.map(node => {
          const children = node.children || [];
          if (node.id === newPosition.parentId) {
            node.children = [...children];
            node.children.splice(newPosition.index, 0, item);
          } else if (children.length > 0) {
            node.children = insertItem(children, node.id, item);
          }
          return node;
        });
      };

      const [itemsWithoutMoved, movedItem] = removeItem(clonedItems, null);
      if (movedItem) {
        const result = insertItem(itemsWithoutMoved, null, movedItem);
        onItemsChange(result);
        return result;
      }
      
      return clonedItems;
    });
  }, [onItemsChange]);

  const findItemById = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | undefined => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findItemById(node.children, id);
        if (found) return found;
      }
    }
    return undefined;
  };

  const handleEditNode = useCallback((nodeId: string) => {
    setEditingNode(nodeId);
  }, []);

  const handleCloseEdit = useCallback(() => {
    setEditingNode(null);
  }, []);

  const handleSaveEdit = useCallback((nodeId: string, options: NodeEditOptions) => {
    setItems(prevItems => {
      const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.map(node => {
          if (node.id === nodeId) {
            return {
              ...node,
              type: options.type,
              isLocked: options.isLocked,
              meta: options.isPC 
                ? [...(node.meta || []).filter(m => m.key !== 'PC'), { key: 'PC', value: '1' }]
                : (node.meta || []).filter(m => m.key !== 'PC')
            };
          }
          if (node.children) {
            return { ...node, children: updateNode(node.children) };
          }
          return node;
        });

      const newItems = updateNode(prevItems);
      onItemsChange(newItems);
      return newItems;
    });
  }, [onItemsChange]);

  const editingNodeData = useMemo(() => {
    if (!editingNode) return null;
    const findNode = (nodes: ExtendedGenNode[]): ExtendedGenNode | null => {
      for (const node of nodes) {
        if (node.id === editingNode) return node;
        if (node.children) {
          const found = findNode(node.children);
          if (found) return found;
        }
      }
      return null;
    };
    return findNode(items);
  }, [editingNode, items]);

  const renderTreeItems = (nodes: ExtendedGenNode[]) => (
    nodes.map((node) => (
      <CustomTreeItem
        key={node.id}
        id={node.id}
        itemId={node.id}
        label={node.name || node.value1 || ''}
        node={node}
        onDelete={handleDeleteNode}
        onEdit={handleEditNode}
      >
        {node.children && renderTreeItems(node.children)}
      </CustomTreeItem>
    ))
  );

  const handleRemoveNode = (nodeId: string) => {
    setItems(prev => {
      const removeNodeFromTree = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
        return nodes.filter(node => {
          if (node.id === nodeId) {
            return false;
          }
          if (node.children) {
            node.children = removeNodeFromTree(node.children);
          }
          return true;
        });
      };

      const newItems = removeNodeFromTree([...prev]);
      onItemsChange(newItems);
      return newItems;
    });
  };

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>, nodeId: string) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOverNodeId(nodeId);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOverNodeId(null);
  }, []);

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>, targetId: string | null) => {
    event.preventDefault();
    const rawData = event.dataTransfer.getData('application/json');
    console.log("Received raw drag data:", rawData);
    
    if (!rawData) {
      console.log("No drag data received");
      return;
    }

    try {
      const dragData = JSON.parse(rawData) as DragPayload;
      console.log("Parsed drag data:", dragData);

      if (dragData.treeType === 'variable') {
        // Create new node from drag data
        const newNode: ExtendedGenNode = {
          type: dragData.nodeType,
          id: `${dragData.nodeId}-${Date.now()}`,
          parentId: targetId,
          isExpanded: dragData.isExpanded,
          isFolder: dragData.isFolder,
          value1: dragData.value1,
          value2: dragData.value2,
          meta: dragData.meta,
          level: dragData.level,
          anyChildren: dragData.anyChildren,
          name: dragData.name,
          children: dragData.children?.map(child => ({
            ...child,
            id: `${dragData.nodeId}-${Date.now()}_${child.id}`,
            parentId: `${dragData.nodeId}-${Date.now()}`
          }))
        };

        setItems(prev => {
          const updatedItems = moveItem(prev, null, targetId, newNode);
          return updatedItems;
        });
      } else {
        // Handle regular node movement
        setItems(prev => {
          const updatedItems = moveItem(prev, dragData.nodeId, targetId);
          return updatedItems;
        });
      }
    } catch (error) {
      console.error("Error handling drop:", error);
    }
  };

  return (
    <Box 
      sx={{ 
        height: '100%', 
        width: '100%', 
        overflow: 'auto',
        position: 'relative',
        '&[data-can-drop="true"]': {
          backgroundColor: 'action.hover',
          outline: '2px dashed',
          outlineColor: 'primary.main'
        }
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      
      {editingNodeData && (
        <NodeEditModal
          node={editingNodeData}
          open={true}
          onClose={handleCloseEdit}
          onSave={handleSaveEdit}
        />
      )}
      <GlobalStyles
        styles={{
          '.tree-item-content:hover .actions': {
            visibility: 'visible !important',
            opacity: '1 !important',
          },
        }}
      />
      {items.length > 0 ? (
        <RichTreeViewPro
          items={items}
          defaultExpandedItems={getAllNodeIds(items)}
          getItemId={(item) => item.id}
          getItemLabel={(item) => `${item.type}${item.value1 ? ` - ${item.value1}` : ''}${item.value2 ? ` - ${item.value2}` : ''}`}
          onItemPositionChange={(params) => {
            handleItemMove(params.itemId, params.oldPosition, params.newPosition);
          }}
          experimentalFeatures={{
            indentationAtItemLevel: true,
            itemsReordering: true
          }}
          itemsReordering
          slots={{
            item: CustomTreeItem as React.ComponentType<TreeItem2Props>
          }}
          slotProps={{
            item: (ownerState) => ({
              ...ownerState,
              node: findItemById(items, ownerState.itemId) as ExtendedGenNode,
              onDelete: handleDeleteNode,
              onEdit: handleEditNode,
              contentprops: {
                style: {
                  padding: '4px 0',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                },
                className: 'tree-item-content',
              }
            })
          }}
        />
      ) : (
        <Typography 
          sx={{ 
            p: 2, 
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px dashed',
            borderColor: 'divider'
          }}
        >
          Drag and drop nodes here to add them
        </Typography>
      )}
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Remove</DialogTitle>
        <DialogContent>
          <Typography id="delete-dialog-description">
            Are you sure you want to remove this node?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NodeTreeEditor; 