import React from 'react';
import { Box } from '@mui/material';
import Header from './Header';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#999' }}>
      <Header />
      <Box component="main" sx={{ flexGrow: 1, position: 'relative', mt: '64px' }}>
        {children}
      </Box>
    </Box>
  );
}; 