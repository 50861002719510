import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Box,
  Typography
} from '@mui/material';
import { ExtendedGenNode } from '../types/index';

export interface NodeEditOptions {
  type: 'Code' | 'Net' | 'Sum' | 'Mean';
  isLocked: boolean;
  isPC: boolean;
}

interface NodeEditModalProps {
  node: ExtendedGenNode;
  open: boolean;
  onClose: () => void;
  onSave: (nodeId: string, options: NodeEditOptions) => void;
}

const isValidNodeType = (type: string): type is NodeEditOptions['type'] => {
  return ['Code', 'Net', 'Sum', 'Mean'].includes(type);
};

export const NodeEditModal: React.FC<NodeEditModalProps> = ({
  node,
  open,
  onClose,
  onSave,
}) => {
  const [options, setOptions] = React.useState<NodeEditOptions>(() => ({
    type: isValidNodeType(node.type) ? node.type : 'Code',
    isLocked: node.isLocked || false,
    isPC: node.meta?.some(m => m.key === 'PC' && m.value === '1') || false,
  }));

  React.useEffect(() => {
    setOptions({
      type: isValidNodeType(node.type) ? node.type : 'Code',
      isLocked: node.isLocked || false,
      isPC: node.meta?.some(m => m.key === 'PC' && m.value === '1') || false,
    });
  }, [node]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = event.target.value;
    if (isValidNodeType(newType)) {
      setOptions(prev => ({
        ...prev,
        type: newType,
      }));
    }
  };

  const handleLockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(prev => ({
      ...prev,
      isLocked: event.target.checked,
    }));
  };

  const handlePCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(prev => ({
      ...prev,
      isPC: event.target.checked,
    }));
  };

  const handleSave = () => {
    onSave(node.id, options);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        Edit Node: {node.name || node.value1 || node.type}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Type
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup value={options.type} onChange={handleTypeChange}>
              <FormControlLabel value="Code" control={<Radio />} label="Code" />
              <FormControlLabel value="Net" control={<Radio />} label="Net" />
              <FormControlLabel value="Sum" control={<Radio />} label="Sum" />
              <FormControlLabel value="Mean" control={<Radio />} label="Mean" />
            </RadioGroup>
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={options.isLocked}
                  onChange={handleLockChange}
                />
              }
              label="Locked"
            />
          </Box>

          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={options.isPC}
                  onChange={handlePCChange}
                />
              }
              label="Percentage"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 