import axios, { AxiosInstance } from 'axios'
import {
  SessionInfo,
  RunSpecRequest,
  GenericResponse,
  ChangePasswordRequest,
  UpdateAccountRequest,
  OpenCloudJobResponse,
  XlsxResponse,
  MultiOxtRequest,
  MultiOxtResponse,
  QuickUpdateRequest,
  ValidateExpRequest,
  AzDashboard,
  UpsertDashboardRequest,
  DashboardRequest,
  TocNode,
  GenNode,
  ServiceInfo,
  SessionStatus,
  CloseJobResponse,
  LogoffResponse,
  SpecAggregate,
  GenTabRequest,
  XDisplayProperties, // Add this import if not already present
  ICarbonClient, // Make sure this import is present
  JobInfo,
  CustomerInfo,
  ResetPasswordRequest,
  ImportSettings
} from '../types/types'

const BASE_URL = process.env.REACT_APP_BPR_CARBON_URL || 'https://bayesprice.azurewebsites.net/carbontest/'
const LICENSING_URL = process.env.REACT_APP_BPR_LICENSING_URL || 'https://bayesprice.helix.ml/licensingtest/'

export class CarbonClient implements ICarbonClient {
  private axiosInstance: AxiosInstance
  private licensingAxiosInstance: AxiosInstance
  private sessionInfo: SessionInfo | null = null
  private isJobOpen: boolean = false

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.licensingAxiosInstance = axios.create({
      baseURL: LICENSING_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // Add an interceptor to always include the session ID if available
    this.axiosInstance.interceptors.request.use((config) => {
      if (this.sessionInfo && this.sessionInfo.sessionId) {
        config.headers['x-session-id'] = this.sessionInfo.sessionId
        console.log('Adding x-session-id to request:', this.sessionInfo.sessionId)
      } else {
        console.log('No session ID available for request')
      }
      return config
    })
  }

  setSessionInfo(sessionInfo: SessionInfo | null) {
    this.sessionInfo = sessionInfo
  }

  isAuthenticated(): boolean {
    return this.isAuthenticated !== null
  }

  // Comment: This method aligns with the /session/start/authenticate/name endpoint in the swagger spec
  async authenticate(name: string, password: string): Promise<SessionInfo> {
    const response = await this.axiosInstance.post('session/start/authenticate/name', {
      name,
      password,
      skipCache: true,
    })
    const sessionInfo = response.data
    this.setSessionInfo(sessionInfo)
    return sessionInfo
  }

  // Update openCloudJob method
  async openCloudJob(customerName: string, job: JobInfo): Promise<OpenCloudJobResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    
    const requestBody = {
      sessionId: this.sessionInfo?.sessionId,
      customerName,
      jobName: job.name,
      vartreeName: "VarTree", // Default vartree name
      getDisplayProps: true,
      getVartreeNames: true,
      getAxisTreeNames: true,
      tocType: 1, // Assuming JobTocType.Full
      getDrills: true
    }
    
    try {
      const response = await this.axiosInstance.post<OpenCloudJobResponse>('job/open', requestBody);
      this.isJobOpen = true;

      // Save dProps to localStorage
      if (response.data.dProps) {
        console.log('dProps received:', response.data.dProps);
        localStorage.setItem('dProps', JSON.stringify(response.data.dProps));
        console.log('dProps saved to localStorage');
      } else {
        console.warn('No dProps received in the response');
      }

      return response.data;
    } catch (error) {
      console.error('Error opening cloud job:', error);
      throw error;
    }
  }

  // Comment: Method to close the job
  async closeJob(): Promise<CloseJobResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.delete('job/close')
    this.isJobOpen = false
    return response.data
  }

  // Comment: Method to logoff the session
  async logoff(): Promise<LogoffResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.delete('session/end/logoff')
    return response.data
  }

  async getTocNodes(): Promise<TocNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    
    try {
      console.log('Fetching TOC nodes...');
      const response = await this.axiosInstance.get<TocNode[]>(`job/toc/simple/true`);
      console.log('TOC nodes response:', response.data);
      
      if (!response.data || !Array.isArray(response.data)) {
        throw new Error('Invalid response data for TOC nodes');
      }

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error response:', error.response?.data);
        throw new Error(`Failed to fetch TOC nodes: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // Update the mapToGenNodes method
  private mapToGenNodes(nodes: any[], parentId: string | null = null): TocNode[] {
    return nodes.map(node => {
      // Determine the node type
      const nodeType = this.getNodeType(node.type)

      // Create the TocNode object
      const tocNode: TocNode = {
        name: node.value1 || '',
        type: nodeType,
        id: node.id,
        parentId: parentId || null,
        isExpanded: false,
        isFolder: nodeType === 'Folder',
        value1: node.value1,
        value2: node.value2,
        meta: node.meta || null,
        children: node.children ? this.mapToGenNodes(node.children, node.id) : null,
        level: 0,
        anyChildren: !!node.children
      }

      // Set additional properties based on node type
      switch (nodeType) {
        case 'Section':
        case 'Folder':
        case 'Table':
        case 'User':
          tocNode.value1 = node.value1 || ''
          tocNode.value2 = node.value2 || ''
          break
        default:
          // For unknown types, we'll keep the default values
          break
      }

      return tocNode
    })
  }

  // Comment: Helper method to determine the node type
  private getNodeType(type: string): string {
    // Define known types
    const knownTypes = ['Section', 'Folder', 'Table', 'User']
    
    // If the type is known, return it; otherwise, return 'Unknown'
    return knownTypes.includes(type) ? type : 'Unknown'
  }

  // Update setVartreeName method
  async setVartreeName(treeName: string, jobName: string, customerName: string): Promise<boolean> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/vartree/set', {
      treeName,
      jobName,
      customerName
    })
    return response.data
  }

  // Comment: This method aligns with the /job/vartree/nodes endpoint in the swagger spec
  async variableTreeAsNodes(): Promise<TocNode[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get('job/vartree/nodes')
    return response.data
  }

  // Update method signature to match ICarbonClient interface
  async runSpec(spec: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Running specification:', spec);
      const response = await this.axiosInstance.post<GenericResponse>('job/spec', spec);
      console.log('Run specification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error running spec:', error);
      throw error;
    }
  }

  // Update loadReport method
  async LoadReportAsync(value1: string, value2: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      // Combine value2 and value1, remove any leading or trailing slashes
      const combinedPath = `${value2}/${value1}`.replace(/^\/+|\/+$/g, '');
      
      // Add .cbt extension only if it doesn't already exist
      const reportName = combinedPath.toLowerCase().endsWith('.cbt') 
        ? combinedPath 
        : `${combinedPath}.cbt`;
      
      console.log(`Attempting to load report: ${reportName}`);
      const requestData = { Name: reportName };
      console.log('Request data:', JSON.stringify(requestData, null, 2));
      console.log('Full request config:', JSON.stringify(this.axiosInstance.defaults, null, 2));
      const response = await this.axiosInstance.post<GenericResponse>('job/report/load', requestData);
      console.log('Load report response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error loading report:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        return error.response.data as GenericResponse;
      }
      throw new Error(`Failed to load report: ${(error as Error).message}`);
    }
  }

  // Comment: This method aligns with the /job/report/xlsx endpoint in the swagger spec
  async getReportXlsx(reportName: string): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    
    try {
      const response = await this.axiosInstance.get<XlsxResponse>('job/report/xlsx', { params: { reportName } });
      return response.data;
    } catch (error) {
      console.error('Error fetching XLSX:', error);
      throw error;
    }
  }

  // Update multiOxt method
  async multiOxt(request: MultiOxtRequest): Promise<MultiOxtResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/report/multioxt', request)
    return response.data
  }

  // Update quickUpdate method
  async quickUpdate(request: QuickUpdateRequest): Promise<XlsxResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/report/quickupdate', request)
    return response.data
  }

  // Implement listSessions method
  async listSessions(): Promise<SessionStatus[]> {
    const response = await this.axiosInstance.get('session/list')
    return response.data
  }

  // Update the method signature to match ICarbonClient interface
  async changePassword(request: ChangePasswordRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Changing password');
      const response = await this.licensingAxiosInstance.post<GenericResponse>('user/password/change', request);
      console.log('Change password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error changing password:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to change password: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to change password: ${(error as Error).message}`);
    }
  }

  async updateAccount(request: UpdateAccountRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    // Implement the API call using the request object
    const response = await this.axiosInstance.post('account/update', request)
    return response.data
  }

  async getServiceInfo(): Promise<ServiceInfo> {
    const response = await this.axiosInstance.get('service/info')
    return response.data
  }

  async listDashboards(customerName: string, jobName: string): Promise<AzDashboard[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get(`dashboard/list/${customerName}/${jobName}`)
    return response.data
  }

  // Comment: Implement getDashboard method
  async getDashboard(request: DashboardRequest): Promise<AzDashboard> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/get', request)
    return response.data
  }

  // Comment: Implement deleteDashboard method
  async deleteDashboard(request: DashboardRequest): Promise<boolean> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/delete', request)
    return response.data
  }

  // Comment: Implement upsertDashboard method
  async upsertDashboard(request: UpsertDashboardRequest): Promise<AzDashboard> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/upsert', request)
    return response.data
  }

  // Comment: Implement validateExpression method
  async validateExpression(request: ValidateExpRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/validate/expression', request)
    return response.data
  }

  // Implement listVartrees method
  async listVartrees(): Promise<string[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get('job/vartree/list')
    return response.data
  }

  // Comment: Add getCustomers method to retrieve customer names from sessionInfo
  async getCustomers(): Promise<CustomerInfo[]> {
    if (!this.sessionInfo) {
      throw new Error('Not authenticated')
    }
    return this.sessionInfo.sessionCusts;
  }

  // Comment: Add getJobs method to retrieve job names for a specific customer
  async getJobs(customerName: string): Promise<JobInfo[]> {
    if (!this.sessionInfo) {
      throw new Error('Not authenticated')
    }
    const customer = this.sessionInfo.sessionCusts.find(cust => cust.name === customerName)
    if (!customer) {
      throw new Error(`Customer ${customerName} not found`)
    }
    return customer.sessionJobs;
  }

  // Add this method to your CarbonClient class
  async getVarNodes(nodeName: string): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    if (!nodeName) {
      console.error('Node name is empty');
      return [];
    }
    try {
      console.log(`Fetching variables for node: ${nodeName}`);
      const response = await this.axiosInstance.get<GenNode[]>(`job/varnodes/${encodeURIComponent(nodeName)}`);
      console.log(`Response for ${nodeName}:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching variables for node ${nodeName}:`, error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);
        console.error('Full error response:', error.response);
        if (error.response?.status === 404 || error.response?.status === 500) {
          console.log(`No variables found for node ${nodeName}, returning empty array`);
          return [];
        }
      }
      throw error;
    }
  }

  async getAxisNodes(nodeName: string): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>(`job/axisnodes/${nodeName}`);
    return response.data;
  }

  async getFunctionNodes(nodeName: string): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>(`job/functionnodes/${nodeName}`);
    return response.data;
  }

  async getSpecAggregate(): Promise<SpecAggregate> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    const response = await this.axiosInstance.get<SpecAggregate>('job/spec/edit');
    return response.data;
  }

  // Add these methods to your CarbonClient class
  async getVariableTree(): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get<GenNode[]>('job/vartree/nodes');
    return response.data;
  }

  async getAxisTree(): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>('job/axistree/nodes');
    return response.data;
  }

  async getFunctionTree(): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>('job/function/nodes');
    return response.data;
  }

  public async initialize(): Promise<void> {
    // Implement the initialization logic here
    // For example:
    try {
      // Perform any necessary setup
      console.log('CarbonClient initialized successfully');
    } catch (error) {
      console.error('Failed to initialize CarbonClient:', error);
      throw error;
    }
  }

  async startJob(jobType: 'sentiment' | 'complaint-theme', data: any): Promise<any> {
    const response = await this.axiosInstance.post(`jobs/${jobType}`, data);
    return response.data;
  }

  async getMetadata(jobId: string): Promise<any> {
    const response = await this.axiosInstance.get(`Surveys/${jobId}/Metadata`);
    return response.data;
  }

  async getInterviews(jobId: string): Promise<any> {
    const response = await this.axiosInstance.get(`Surveys/${jobId}/Interviews`);
    return response.data;
  }

  async deleteJob(jobId: string): Promise<void> {
    await this.axiosInstance.delete(`Surveys/${jobId}`);
  }

  // Add this method to the CarbonClient class
  async updateReportDisplay(options: QuickUpdateRequest): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    const response = await this.axiosInstance.post<XlsxResponse>('job/report/quickupdate', options);
    return response.data;
  }

  // Add this public method to check if a job is open
  public isJobCurrentlyOpen(): boolean {
    return this.isJobOpen;
  }

  async getCurrentSpec(): Promise<RunSpecRequest | null> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<RunSpecRequest>('job/spec/edit');
      return response.data;
    } catch (error) {
      console.error('Error getting current spec:', error);
      return null;
    }
  }

  async getNewSpec(): Promise<RunSpecRequest | null> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<RunSpecRequest>('job/spec/edit', {});
      return response.data;
    } catch (error) {
      console.error('Error getting new spec:', error);
      return null;
    }
  }

  async saveSpec(spec: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Saving specification:', spec);
      const response = await this.axiosInstance.post<GenericResponse>('job/spec/save', spec);
      console.log('Save specification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error saving spec:', error);
      throw error;
    }
  }

  async editSpec(spec: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Editing specification:', spec);
      const response = await this.axiosInstance.put<GenericResponse>('job/spec/edit', spec);
      console.log('Edit specification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error editing spec:', error);
      throw error;
    }
  }

  public getBaseUrl(): string {
    return BASE_URL;
  }

  public getHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      ...(this.sessionInfo?.sessionId ? { 'x-session-id': this.sessionInfo.sessionId } : {})
    };
  }

  async runSpecification(request: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending runSpecification request to:', `${this.axiosInstance.defaults.baseURL}job/spec`);
    console.log('Request data:', JSON.stringify(request, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec', request);
      console.log('RunSpecification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in runSpecification:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  async saveCurrentSpec(spec: RunSpecRequest): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      await this.axiosInstance.post('job/spec/save', spec);
    } catch (error) {
      console.error('Error saving current spec:', error);
      throw error;
    }
  }

  public validateSpec = async (spec: RunSpecRequest['spec']): Promise<GenericResponse> => {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending validateSpec request to:', `${this.axiosInstance.defaults.baseURL}job/spec/validate/spec`);
    console.log('Request data:', JSON.stringify(spec, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec/validate/spec', spec);
      console.log('ValidateSpec response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in validateSpec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  public async saveReport(name: string, sub: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      console.error('Authentication check failed in saveReport');
      throw new Error('Not authenticated');
    }
    console.log('Sending saveReport request with name:', name, 'and sub (folder):', sub);
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/report/save', { name, sub });
      console.log('SaveReport response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in saveReport:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
        throw new Error(`SaveReport failed: ${error.response?.status} - ${JSON.stringify(error.response?.data)}`);
      }
      throw new Error(`SaveReport failed: ${(error as Error).message}`);
    }
  }

  async setOutputFormat(format: string): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      await this.axiosInstance.post('job/report/format', { format });
    } catch (error) {
      console.error('Error setting output format:', error);
      throw error;
    }
  }

  private createDefaultGenTabRequest(reportName: string): GenTabRequest {
    return {
      name: reportName,
      top: '',
      side: '',
      sProps: {
        caseFilter: null,
        initAsMissing: false,
        excludeNE: false,
        padHierarchics: false,
        arithOverStats: false,
        topInsert: null,
        sideInsert: null,
        level: null,
        fullStats: false // Add this line
      },
      dProps: {}
    };
  }

  async getReportHtml(reportName: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const genTabRequest = this.createDefaultGenTabRequest(reportName);
      const response = await this.axiosInstance.post<string>('job/gentab', genTabRequest);
      return response.data;
    } catch (error) {
      console.error('Error getting HTML report:', error);
      throw error;
    }
  }

  async GenTab(request: GenTabRequest, format: string | number = 'HTML'): Promise<any> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      let url: string;
      if (format === 'JSON') {
        url = `${this.getBaseUrl()}/report/gentab/pandas/1`;
      } else {
        url = `${this.getBaseUrl()}/report/gentab/text/${format}`;
      }
      const response = await this.axiosInstance.post<any>(url, request);
      return response.data;
    } catch (error) {
      console.error('Error generating report:', error);
      throw error;
    }
  }

  async getReportText(reportName: string, format: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const genTabRequest = this.createDefaultGenTabRequest(reportName);
      const response = await this.axiosInstance.post<string>(`report/gentab/text/${format}`, genTabRequest);
      return response.data;
    } catch (error) {
      console.error(`Error generating ${format} report:`, error);
      throw error;
    }
  }

  async getReportPandas(reportName: string, shape: number): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>(`report/gentab/pandas/${shape}`, {
        name: reportName
      });
      return response.data;
    } catch (error) {
      console.error('Error generating Pandas report:', error);
      throw error;
    }
  }

  private async ensureJobOpen(customerName: string, job: JobInfo): Promise<void> {
    if (!this.isJobOpen) {
      await this.openCloudJob(customerName, job);
      this.isJobOpen = true;
    }
  }

  async getProperties(customerName: string, jobName: string): Promise<XDisplayProperties> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<XDisplayProperties>('job/props');
      return response.data;
    } catch (error) {
      console.error('Error fetching properties:', error);
      throw error;
    }
  }

  async generateXlsx(): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Generating XLSX report');
      const response = await this.axiosInstance.get<XlsxResponse>('job/report/xlsx');
      console.log('Generate XLSX response:', response.data);
      if (response.data.reportName) {
        console.log(`XLSX generated for report: ${response.data.reportName}`);
      } else {
        console.warn('XLSX generated, but no report name provided in the response');
      }
      return response.data;
    } catch (error) {
      console.error('Error generating XLSX report:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to generate XLSX report: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to generate XLSX report: ${(error as Error).message}`);
    }
  }

  async GenTabAsHTML(top: string, side: string, filter: string, weight: string, caseFilter: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Generating HTML table with params:', { top, side, filter, weight, caseFilter });
      const response = await this.axiosInstance.post<string>('report/gentab/html', { top, side, filter, weight, caseFilter });
      console.log('GenTabAsHTML response length:', response.data.length);
      return response.data;
    } catch (error) {
      console.error('Error in GenTabAsHTML:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to generate HTML table: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to generate HTML table: ${(error as Error).message}`);
    }
  }

  async AxisSyntaxToNodes(syntax: string): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<GenNode[]>('report/axis/syntaxtonodes', { syntax });
      return response.data;
    } catch (error) {
      console.error('Error in AxisSyntaxToNodes:', error);
      throw error;
    }
  }

  async AxisNodesToSyntax(gn: GenNode[]): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>('report/axis/nodestosyntax', { nodes: gn });
      return response.data;
    } catch (error) {
      console.error('Error in AxisNodesToSyntax:', error);
      throw error;
    }
  }

  async CurrentSyntax(): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<string>('report/syntax/current');
      return response.data;
    } catch (error) {
      console.error('Error in CurrentSyntax:', error);
      throw error;
    }
  }

  async ValidateSyntax(syntax: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>('report/syntax/validate', { syntax });
      return response.data;
    } catch (error) {
      console.error('Error in ValidateSyntax:', error);
      throw error;
    }
  }

  async getReportSyntax(): Promise<string[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Fetching report syntax');
      const response = await this.axiosInstance.get<string | string[]>('report/syntax');
      console.log('Report syntax response:', response.data);
      
      let syntaxArray: string[];
      
      if (Array.isArray(response.data)) {
        syntaxArray = response.data;
      } else if (typeof response.data === 'string') {
        syntaxArray = response.data.split('\n').map(line => line.trim()).filter(line => line !== '');
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Unexpected response format from report/syntax endpoint');
      }
      
      // Ensure we have at least 5 elements (including the Case filter)
      while (syntaxArray.length < 5) {
        syntaxArray.push('');
      }
      
      return syntaxArray;
    } catch (error) {
      console.error('Error fetching report syntax:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to fetch report syntax: ${error.response.data.message || error.message}`);
      }
      throw error;
    }
  }

  // Add this new method to the CarbonClient class
  async getReportFormatHtml(): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Fetching HTML report format');
      const response = await this.axiosInstance.get<string>('report/format/html');
      console.log('HTML report format response length:', response.data.length);
      return response.data;
    } catch (error) {
      console.error('Error fetching HTML report format:', error);
      throw error;
    }
  }

  async resetPassword(request: ResetPasswordRequest): Promise<GenericResponse> {
    try {
      console.log('Resetting password');
      const response = await this.licensingAxiosInstance.post<GenericResponse>('user/password/reset', request);
      console.log('Reset password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to reset password: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to reset password: ${(error as Error).message}`);
    }
  }

  // Add this method to the CarbonClient class
  async endSession(sessionId: string): Promise<void> {
    try {
      await this.axiosInstance.delete('session/end', {
        headers: {
          'x-session-id': sessionId
        }
      });
      console.log(`Session ${sessionId} ended successfully`);
    } catch (error) {
      console.error(`Error ending session ${sessionId}:`, error);
      throw error;
    }
  }

  async importPartialJob(importSettings: ImportSettings): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Importing partial job with settings:', importSettings);
      console.log('Current session ID:', this.sessionInfo?.sessionId);
      const response = await this.axiosInstance.post<GenericResponse>('job/import/partial', importSettings);
      console.log('Import partial job response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error importing partial job:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        throw new Error(`Failed to import partial job: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to import partial job: ${(error as Error).message}`);
    }
  }

  async importFullJob(importSettings: ImportSettings): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Importing full job with settings:', importSettings);
      const response = await this.axiosInstance.post<GenericResponse>('job/import/full', importSettings);
      console.log('Import full job response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error importing full job:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to import full job: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to import full job: ${(error as Error).message}`);
    }
  }

  async getReportFormat(format: string): Promise<string[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<string[]>(`report/format/${format}`);
      return response.data;
    } catch (error) {
      console.error('Error getting report format:', error);
      throw error;
    }
  }
}