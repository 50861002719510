import React, { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Typography, TextField, Button, Paper, useTheme, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { TocNode, ExtendedGenNode, RunSpecRequest, SpecAggregate, GenericResponse } from '../types/types';
import { useNavigate } from 'react-router-dom';

export const Tables: React.FC = () => {
  const { carbonClient, isAuthenticated, isClientReady } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const [nodes, setNodes] = useState<ExtendedGenNode[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const [htmlReport, setHtmlReport] = useState<string | null>(null);
  const [top, setTop] = useState<string>('');
  const [side, setSide] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [caseFilter, setCaseFilter] = useState<string>('');

  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>('');

  const [specAggregate, setSpecAggregate] = useState<SpecAggregate | null>(null);
  const [currentSpec, setCurrentSpec] = useState<RunSpecRequest | null>(null);

  const [reportSyntax, setReportSyntax] = useState<string[]>([]);
  const [isTableSelected, setIsTableSelected] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isTableLoading, setIsTableLoading] = useState(false);

  const theme = useTheme();

  const navigate = useNavigate();

  const mapToExtendedGenNode = useCallback((node: TocNode): ExtendedGenNode => ({
    ...node,
    id: node.id.toString(),
    label: node.value1 || node.name || 'Unnamed Node',
    children: node.children ? node.children.map(mapToExtendedGenNode) : undefined,
    value1: node.value1 || null,
    value2: node.value2 || null,
    meta: node.meta || null,
    isParentInfo: false,
    isPC: false,
    isBase: false,
    content: undefined,
    name: node.name,
    type: node.type,
    parentId: node.parentId,
    isExpanded: node.isExpanded,
    isFolder: node.isFolder,
    level: node.level,
    anyChildren: node.anyChildren,
  }), []);

  const loadTocNodes = useCallback(async () => {
    if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
      try {
        setLoading(true);
        await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        const tocNodes = await carbonClient.getTocNodes();
        const mappedNodes = tocNodes.map(mapToExtendedGenNode);
        setNodes(mappedNodes);
      } catch (error) {
        console.error('Error opening job or fetching TOC nodes:', error);
        setErrorMessage(`Error: ${(error as Error).message}`);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      if (!carbonClient || !isClientReady) {
        setErrorMessage('Carbon client is not available or not ready');
      } else if (!selectedCustomer || !selectedJob) {
        setErrorMessage('Please select a customer and job');
      }
    }
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob, mapToExtendedGenNode]);

  useEffect(() => {
    console.log('Auth state:', { isAuthenticated, isClientReady, carbonClient: !!carbonClient });
    console.log('Job state:', { selectedCustomer, selectedJob });
    loadTocNodes();
  }, [isAuthenticated, isClientReady, carbonClient, selectedCustomer, selectedJob, loadTocNodes]);

  const getFullPath = useCallback((nodes: ExtendedGenNode[], nodeId: string): string => {
    const path: string[] = [];
    const findPath = (nodes: ExtendedGenNode[], id: string): boolean => {
      for (const node of nodes) {
        if (node.id === id) {
          path.unshift(node.name);
          return true;
        }
        if (node.children && findPath(node.children, id)) {
          path.unshift(node.name);
          return true;
        }
      }
      return false;
    };
    findPath(nodes, nodeId);
    return path.join('/');
  }, []);

  const findNodeById = useCallback((nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    console.log('Searching for node with id:', id);
    console.log('Current nodes:', JSON.stringify(nodes, null, 2));

    const queue = [...nodes];
    while (queue.length > 0) {
      const node = queue.shift();
      if (node) {
        console.log('Checking node:', node.id, node.name);
        if (node.id.toString() === id.toString()) {
          console.log('Node found:', node);
          return node;
        }
        if (node.children) {
          queue.push(...node.children);
        }
      }
    }
    console.log('Node not found');
    return null;
  }, []);

  const handleReportSelect = useCallback(async (nodeId: string) => {
    setErrorMessage(null);
    console.log('handleReportSelect called with nodeId:', nodeId);

    try {
      if (!carbonClient || !isAuthenticated || !isClientReady) {
        throw new Error('Carbon client is not initialized or not ready');
      }

      setIsTableLoading(true);

      // Find the selected node
      const selectedNode = findNodeById(nodes, nodeId);
      if (!selectedNode) {
        throw new Error('Selected node not found');
      }

      if (selectedNode.type !== 'Table') {
        console.log('Selected node is not a table, skipping report load');
        setIsTableSelected(false);
        return;
      }

      // Use value1 and value2 for the report name
      const value1 = selectedNode.value1 || selectedNode.name;
      const value2 = selectedNode.value2 || '';
      if (!value1) {
        throw new Error('Report name not found');
      }

      // 1. Load the report
      const loadResponse = await carbonClient.LoadReportAsync(value1, value2);
      console.log('Load report response:', loadResponse);

      if (loadResponse.code !== 0) {
        throw new Error(`Failed to load report: ${loadResponse.message}`);
      }

      // 2. Fetch the report syntax
      const reportSyntax = await carbonClient.getReportSyntax();
      console.log('Report syntax:', reportSyntax);

      // Update the state with the syntax
      if (reportSyntax.length >= 5) {
        setTop(reportSyntax[0] || '');
        setSide(reportSyntax[1] || '');
        setFilter(reportSyntax[2] || '');
        setWeight(reportSyntax[3] || '');
        setCaseFilter(reportSyntax[4] || '');
      }

      setSelectedReport(value1);
      setIsTableSelected(true);

    } catch (error) {
      console.error('Error selecting report:', error);
      setErrorMessage(`Error selecting report: ${(error as Error).message}`);
    } finally {
      setIsTableLoading(false);
      setIsFormDirty(false);
    }
  }, [carbonClient, isAuthenticated, isClientReady, nodes, findNodeById]);

  const handleGenerateTable = useCallback(async () => {
    if (!carbonClient || !isClientReady || !selectedReport) {
      setErrorMessage('Cannot generate table: Client not ready or no report selected');
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);

      // Generate the HTML table using report/format/html
      const result = await carbonClient.getReportFormatHtml();
      console.log('Result from getReportFormatHtml:', result);

      if (!result || result.length === 0) {
        console.warn('Received empty result from getReportFormatHtml');
        setHtmlReport('');
        return;
      }

      // Check if the result is an array of strings
      if (!Array.isArray(result) || !result.every(item => typeof item === 'string')) {
        console.error('Unexpected result type from getReportFormatHtml:', typeof result);
        setErrorMessage(`Unexpected result type: ${typeof result}`);
        return;
      }

      // Join the array of strings into a single string
      const htmlString = result.join('');

      // Process the HTML string
      const cleanedHtmlString = htmlString.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');
      const finalHtmlString = cleanedHtmlString.replace(/\s*style="[^"]*"/gi, '');

      setHtmlReport(finalHtmlString);
    } catch (error) {
      console.error('Error generating table:', error);
      setErrorMessage(`Error generating table: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  }, [carbonClient, isClientReady, selectedReport]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
    setIsFormDirty(true);
  };

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedItems(nodeIds);
  }, []);

  const handleSelectedItemChange = useCallback((event: React.SyntheticEvent, itemIds: string | null) => {
    console.log('handleSelectedItemChange called with event:', event);
    console.log('handleSelectedItemChange called with itemIds:', itemIds);
    if (itemIds) {
      setSelectedItem(itemIds);
      handleReportSelect(itemIds);
    } else {
      setSelectedItem('');
    }
  }, [handleReportSelect, setSelectedItem]);

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
            <Typography variant="body2">{label}</Typography>
          </Box>
        }
      />
    );
  });

  const reportStyles = `
    .Tab1 {
      font-size: 13px;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      font-family: Arial, sans-serif;
      background-color: white;
    }
    .Tab1 td, .Tab1 th {
      border: 1px solid #d0d0d0;
      padding: 8px 12px;
      text-align: left;
      vertical-align: middle;
      min-height: 40px;
      max-height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      word-wrap: break-word;
      white-space: normal;
    }
    .NameTitle, .TopTitle, .SideTitle, .FiltTitle, .WghtTitle, .StatusTitle {
      font-size: 12px;
      color: #333;
      margin-bottom: 8px;
      text-align: center !important;
      font-weight: bold;
    }
    .TopGroup, .TopLabel, .SideGroup, .SideLabel {
      background-color: #f0f0f0;
      font-weight: bold;
      color: #333;
    }
    .SideGroup, .SideLabel {
      text-align: left !important;
      max-width: 200px;
    }
    .Freq, .ColPC, .RowPC, .Base {
      text-align: center !important;
      min-width: 80px;
    }
    .Freq {
      color: #000;
    }
    .ColPC, .RowPC {
      color: #0066cc;
    }
    .Base {
      background-color: #e6e6e6;
      font-weight: bold;
    }
    .Sig1 { color: #cc0000; }
    .Sig2 { color: #ff6600; }
    .Sig3 { color: #ffcc00; }
    .Tab1 tr:hover {
      background-color: #f5f5f5;
    }
    .Key {
      background-color: #f0f0f0;
      font-weight: bold;
      color: #333;
      text-align: left;
    }
    .Tab1 td, .Tab1 th {
      min-width: 60px;
      max-width: 200px;
    }
    /* Tooltip styles */
    .tooltip {
      position: relative;
      display: inline-block;
    }
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 200px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -100px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  `;

  const adjustTable = useCallback(() => {
    const table = document.querySelector('.Tab1') as HTMLTableElement;
    if (table) {
      const rows = table.rows;
      for (let i = 0; i < rows.length; i++) {
        const cells = rows[i].cells;
        for (let j = 0; j < cells.length; j++) {
          const cell = cells[j];
          
          // Remove fixed height and line-height
          cell.style.height = 'auto';
          cell.style.lineHeight = 'normal';
          
          // Add tooltip for content that's still truncated
          if (cell.scrollHeight > cell.clientHeight) {
            cell.classList.add('tooltip');
            const tooltipSpan = document.createElement('span');
            tooltipSpan.className = 'tooltiptext';
            tooltipSpan.textContent = cell.textContent;
            cell.appendChild(tooltipSpan);
          }

          // Align numbers to the right
          if (cell.textContent && !isNaN(Number(cell.textContent.trim()))) {
            cell.style.textAlign = 'right';
          }
        }
      }

      // Set a consistent width for side labels
      const sideLabelCells = table.querySelectorAll('.SideLabel, .SideGroup');
      let maxWidth = 0;
      sideLabelCells.forEach((cell: Element) => {
        const cellWidth = (cell as HTMLElement).offsetWidth;
        if (cellWidth > maxWidth) {
          maxWidth = cellWidth;
        }
      });
      sideLabelCells.forEach((cell: Element) => {
        (cell as HTMLElement).style.width = `${maxWidth}px`;
      });
    }
  }, []);

  useEffect(() => {
    if (htmlReport) {
      // Use setTimeout to ensure the DOM has been updated
      setTimeout(adjustTable, 0);
    }
  }, [htmlReport, adjustTable]);

  const renderTableInfo = (htmlReport: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlReport, 'text/html');
    const infoElements = doc.querySelectorAll('.NameTitle, .TopTitle, .SideTitle, .FiltTitle, .WghtTitle, .StatusTitle');
    let infoContent = '';
    infoElements.forEach((el) => {
      infoContent += el.textContent + '\n';
    });
    return infoContent.trim();
  };

  const renderTable = (htmlReport: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlReport, 'text/html');
    const table = doc.querySelector('.Tab1');
    return table ? table.outerHTML : '';
  };

  const handleEditSpec = async () => {
    if (!carbonClient || !isClientReady || !selectedReport) {
      setErrorMessage('Cannot edit spec: Client not ready or no report selected');
      return;
    }

    try {
      setIsLoading(true);
      const currentSpec = await carbonClient.getCurrentSpec();
      if (currentSpec) {
        navigate('/specification', { state: { loadedSpec: currentSpec, reportName: selectedReport } });
      } else {
        setErrorMessage('Failed to load current specification');
      }
    } catch (error) {
      console.error('Error loading current spec:', error);
      setErrorMessage(`Error loading specification: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden', pl: 2, pr: 2, py: 2 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Left sidebar - TOC */}
          <Paper elevation={0} sx={{ width: 300, pt: 1, pb: 2, px: 2, mr: 2, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>Table of Contents</Typography>
            <RichTreeView<ExtendedGenNode>
              items={nodes}
              expandedItems={expandedItems}
              selectedItems={selectedItem}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemChange}
              getItemId={(item) => item.id.toString()}
              getItemLabel={(item) => item.label || item.name || 'Unnamed Node'}
              slots={{
                item: CustomTreeItem,
              }}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                '& .MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '& .MuiTreeItem-label': {
                  fontSize: '0.875rem',
                },
                '& .MuiTreeItem-group': {
                  marginLeft: 2,
                  paddingLeft: 1,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          </Paper>

          {/* Right side - Report content */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', position: 'relative' }}>
            {/* Loading overlay */}
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            )}

            {/* Top toolbar */}
            <Paper elevation={0} sx={{ px: 2, pt: 1, pb: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Top"
                value={top}
                onChange={handleInputChange(setTop)}
                margin="dense"
                size="small"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                label="Side"
                value={side}
                onChange={handleInputChange(setSide)}
                margin="dense"
                size="small"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                label="Filter"
                value={filter}
                onChange={handleInputChange(setFilter)}
                margin="dense"
                size="small"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                label="Weight"
                value={weight}
                onChange={handleInputChange(setWeight)}
                margin="dense"
                size="small"
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                label="Case Filter"
                value={caseFilter}
                onChange={handleInputChange(setCaseFilter)}
                margin="dense"
                size="small"
                sx={{ mb: 1 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Button
                  variant="contained"
                  onClick={handleGenerateTable}
                  disabled={!isTableSelected || isLoading}
                  sx={{ flex: 1, mr: 1 }}
                  size="small"
                >
                  Generate Table
                </Button>
                <Button
                  variant="contained"
                  onClick={handleEditSpec}
                  disabled={!isTableSelected || isLoading}
                  sx={{ flex: 1, ml: 1 }}
                  size="small"
                >
                  Edit Spec
                </Button>
              </Box>
            </Paper>

            {/* Report content area */}
            <Paper 
              elevation={0}
              sx={{ 
                flex: 1, 
                p: 2, 
                overflowY: 'auto', 
                display: 'flex', 
                flexDirection: 'column',
                bgcolor: theme.palette.background.paper,
                position: 'relative',
              }}
            >
              {errorMessage && (
                <Typography color="error" sx={{ mb: 2 }}>
                  Error: {errorMessage}
                </Typography>
              )}
              {htmlReport ? (
                <Box sx={{ 
                  '& *': { fontFamily: theme.typography.fontFamily },
                  '& table': { borderCollapse: 'separate', borderSpacing: 0 },
                  '& td, & th': { border: 'none' }
                }}>
                  <style>{reportStyles}</style>
                  <div className="table-wrapper" dangerouslySetInnerHTML={{ __html: renderTable(htmlReport) }} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Tooltip title={renderTableInfo(htmlReport)} arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  No report generated yet. Select a report from the table of contents and click "Generate Report".
                </Typography>
              )}
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
};