import React, { createContext, useContext, useState } from 'react';
import { JobInfo } from '../types/types';

interface JobContextType {
  selectedCustomer: string | null;
  selectedJob: JobInfo | null;
  setSelectedCustomer: (customer: string) => void;
  setSelectedJob: (job: JobInfo | null) => void;
}

const JobContext = createContext<JobContextType | undefined>(undefined);

export const JobProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<JobInfo | null>(null);

  return (
    <JobContext.Provider value={{
      selectedCustomer,
      selectedJob,
      setSelectedCustomer,
      setSelectedJob
    }}>
      {children}
    </JobContext.Provider>
  );
};

export const useJob = () => {
  const context = useContext(JobContext);
  if (context === undefined) {
    throw new Error('useJob must be used within a JobProvider');
  }
  return context;
}; 