import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';

export const Dashboard: React.FC = () => {
  const { isAuthenticated, user, carbonClient } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();

  // Get the sessionInfo from localStorage
  const sessionInfo = localStorage.getItem('sessionInfo');
  const parsedSessionInfo = sessionInfo ? JSON.parse(sessionInfo) : null;

  // Find the selected customer object
  const selectedCustomerObject = parsedSessionInfo?.sessionCusts?.find(
    (cust: any) => cust.name === selectedCustomer
  );

  return (
    <Box sx={{ p: 3, height: 'calc(100vh - 64px)', overflow: 'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              User Information
            </Typography>
            {isAuthenticated && user ? (
              <Box>
                <Typography>Welcome, {user.name}!</Typography>
                <Typography>Status: Authenticated</Typography>
              </Box>
            ) : (
              <Typography color="error">Not authenticated</Typography>
            )}
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Current Job
            </Typography>
            {selectedCustomer && selectedJob ? (
              <Box>
                <Typography>Customer: {selectedCustomerObject?.displayName || selectedCustomer}</Typography>
                <Typography>Job: {selectedJob?.displayName || selectedJob?.name || 'No job selected'}</Typography>
              </Box>
            ) : (
              <Typography color="info.main">No job selected</Typography>
            )}
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Session Information
            </Typography>
            {parsedSessionInfo ? (
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {JSON.stringify(parsedSessionInfo, null, 2)}
              </pre>
            ) : (
              <Typography color="error">No session information available</Typography>
            )}
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Typography component="div">
              Navigate to:
              <ul>
                <li>Tables</li>
                <li>Files</li>
                {/* Add more links as needed */}
              </ul>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
