import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';

import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey('3b7d7ceccb05b66d64e5bbcac7c83f84Tz05OTU4MSxFPTE3NjAwMTc5MDkwMDAsUz1wcm8sTE09cGVycGV0dWFsLFBWPVEzLTIwMjQsS1Y9Mg==');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 