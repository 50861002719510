import React, { useState, useEffect, ErrorInfo, useCallback } from 'react';
import { Box, Paper, Typography, TextField, Button, IconButton, Tooltip, Modal, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Folder, ArrowUpward, ArrowDownward, Delete, Percent, Functions, List as ListIcon, ExpandMore, FiberManualRecord as DotIcon, ExpandLess, TableRows, DataArray, FilterAlt, Spa, Calculate, Circle, Label, Crop169, Close } from '@mui/icons-material'
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext'
import { GenNode, SpecAggregate, RunSpecRequest, GenericResponse } from '../types/types'
import axios from 'axios';
import { useJob } from '../contexts/JobContext';
import { useNavigate, useLocation } from 'react-router-dom';
import NodeTreeEditor from '../components/NodeTreeEditor';

// Update the ExtendedGenNode type definition
type ExtendedGenNode = Omit<GenNode, 'children'> & {
  id: string;
  isParentInfo?: boolean;
  isPC?: boolean;
  isBase?: boolean;
  children?: ExtendedGenNode[];
  expandable?: boolean;
  hasChildren?: boolean;
  label?: string;
  content?: React.ReactNode;
};

// Single declaration of mapToExtendedGenNode with proper anyChildren handling
const mapToExtendedGenNode = (node: GenNode): ExtendedGenNode => {
  const isExpandable = Boolean(
    node.type === 'Folder' || 
    node.isFolder || 
    node.type === 'Variable' || 
    node.anyChildren || 
    (node.children && node.children.length > 0)
  );

  return {
    ...node,
    id: node.id.toString(),
    name: node.name || node.value1 || '',
    value1: node.value1 || '',
    value2: node.value2 || '',
    meta: node.meta || [],
    level: node.level || 0,
    expandable: isExpandable,
    hasChildren: isExpandable,
    children: node.children ? node.children.map(mapToExtendedGenNode) : undefined,
    isFolder: node.isFolder || node.type === 'Folder',
    parentId: node.parentId?.toString() || null,
    type: node.type,
  };
};

// Update processNodes to preserve anyChildren
const processNodes = (nodes: GenNode[], parentNode: ExtendedGenNode): ExtendedGenNode[] => {
  return nodes.map((node, index) => ({
    ...node,
    id: (node.id?.toString() || `${parentNode.id}-${index}`),
    parentId: parentNode.id.toString(),
    name: `${node.value1 || ''} ${node.value2 || ''}`.trim(),
    isExpanded: node.type === 'Variable',
    anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
    children: node.children ? processNodes(node.children, node as ExtendedGenNode) : undefined,
  } as ExtendedGenNode));
};

// Update mapNodeToGenNode to preserve anyChildren
const mapNodeToGenNode = (node: ExtendedGenNode): GenNode => ({
  type: node.type,
  id: node.id.toString(),
  parentId: node.parentId?.toString() || null,
  isExpanded: node.isExpanded,
  isFolder: node.isFolder,
  value1: node.value1 || '',
  value2: node.value2 || '',
  meta: node.meta || [],
  level: node.level || 0,
  anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
  children: node.children ? node.children.map(mapNodeToGenNode) : null,
  name: node.name || node.value1 || '',
  isPC: node.isPC || false,
  isBase: node.isBase || false
});

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

interface DragPayload {
  nodeId: string;
  treeType: 'variable';
  nodeType: string;
  value1: string | null;
  value2: string | null;
  parentId: string | null;
  meta: { key: string; value: string }[];
  isExpanded: boolean;
  isFolder: boolean;
  level: number;
  anyChildren: boolean;
  name: string | null;
  children?: ExtendedGenNode[];
}

export const Specification = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { carbonClient, isAuthenticated, isClientReady } = useAuth()
  const { selectedCustomer, selectedJob } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  
  const [customers, setCustomers] = useState<string[]>([])
  const [jobs, setJobs] = useState<string[]>([])
  
  const [specAggregate, setSpecAggregate] = useState<SpecAggregate | null>(null)
  const [variableTree, setVariableTree] = useState<ExtendedGenNode[]>([])
  const [axisTree, setAxisTree] = useState<GenNode[]>([])
  const [functionTree, setFunctionTree] = useState<GenNode[]>([])
  
  const [expandedVariableItems, setExpandedVariableItems] = useState<string[]>([])
  const [selectedVariables, setSelectedVariables] = useState<string[]>([])

  const [topNodes, setTopNodes] = useState<ExtendedGenNode[]>([]);
  const [sideNodes, setSideNodes] = useState<ExtendedGenNode[]>([]);

  const [selectedTopNode, setSelectedTopNode] = useState<ExtendedGenNode | null>(null);
  const [selectedSideNode, setSelectedSideNode] = useState<ExtendedGenNode | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNodeVariables, setSelectedNodeVariables] = useState<string[]>([]);

  const [currentSpec, setCurrentSpec] = useState<RunSpecRequest | null>(null);

  const [isFetching, setIsFetching] = useState(false);

  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [validateResponse, setValidateResponse] = useState<GenericResponse | null>(null);

  const [isRunSpecModalOpen, setIsRunSpecModalOpen] = useState(false);
  const [runSpecResponse, setRunSpecResponse] = useState<GenericResponse | null>(null);

  const [filter, setFilter] = useState('');
  const [weight, setWeight] = useState('');

  const [reportTitle, setReportTitle] = useState<string>(() => {
    const state = location.state as { reportName?: string } | null;
    return state?.reportName || 'Untitled Report';
  });

  const [debugInfo, setDebugInfo] = useState<{
    displayTable: any;
    dProps: any;
    sProps: any;
    job: string;
    customer: string;
    tableSpec: any;
  }>({
    displayTable: null,
    dProps: null,
    sProps: null,
    job: '',
    customer: '',
    tableSpec: null,
  });

  const [isShowSpecModalOpen, setIsShowSpecModalOpen] = useState(false);

  const [selectedVariable, setSelectedVariable] = useState<ExtendedGenNode | null>(null);
  const [isVariableReady, setIsVariableReady] = useState(false);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);

  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState<{ target: 'top' | 'side', parentId: string | null, index: number } | null>(null);

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [newTableName, setNewTableName] = useState('');

  const loadSpec = (spec: RunSpecRequest) => {
    setCurrentSpec(spec);
    console.log('Loading spec:', spec);
    
    if (spec.spec) {
      const mappedTopNodes = spec.spec.topAxis.map(mapToExtendedGenNode);
      const mappedSideNodes = spec.spec.sideAxis.map(mapToExtendedGenNode);
      
      console.log('Mapped top nodes:', mappedTopNodes);
      console.log('Mapped side nodes:', mappedSideNodes);
      
      setTopNodes(prevNodes => {
        if (JSON.stringify(prevNodes) === JSON.stringify(mappedTopNodes)) {
          console.log('No changes in top nodes, skipping update');
          return prevNodes;
        }
        console.log('Updating top nodes');
        return mappedTopNodes;
      });
      setSideNodes(prevNodes => {
        if (JSON.stringify(prevNodes) === JSON.stringify(mappedSideNodes)) {
          console.log('No changes in side nodes, skipping update');
          return prevNodes;
        }
        console.log('Updating side nodes');
        return mappedSideNodes;
      });
      
      // Set filter and weight
      setFilter(spec.spec.filter || '');
      setWeight(spec.spec.weight || '');
    } else {
      console.warn('Loaded spec does not have valid axis data');
    }
  };

  const initializeSpecification = async () => {
    if (!carbonClient || !isClientReady || !selectedCustomer || !selectedJob) {
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      // Always fetch the spec aggregate first to get the latest variable tree
      console.log('Fetching spec aggregate for', selectedCustomer, selectedJob.name);
      const specAggregate = await carbonClient.getSpecAggregate();
      
      if (Array.isArray(specAggregate.variableTree)) {
        const mappedVariableTree = specAggregate.variableTree.map(node => {
          const mapped = mapToExtendedGenNode(node);
          if (mapped.type === 'Variable') {
            return {
              ...mapped,
              children: undefined,
              expandable: true
            };
          }
          return mapped;
        });
        setVariableTree(mappedVariableTree);
      }
      
      if (specAggregate.axisTree) setAxisTree(specAggregate.axisTree);
      if (specAggregate.functionTree) setFunctionTree(specAggregate.functionTree);

      // Check for saved spec in localStorage
      const savedSpec = localStorage.getItem('currentSpec');
      if (savedSpec) {
        const parsedSpec = JSON.parse(savedSpec);
        loadSpec(parsedSpec);
        if (!location.state?.reportName) {
          setReportTitle(parsedSpec.name || 'Untitled Report');
        }
      } else if (specAggregate.spec) {
        // If no saved spec, use the one from specAggregate
        loadSpec(specAggregate.spec);
      }
    } catch (error) {
      console.error('Error initializing specification:', error);
      setErrorMessage(`Error initializing specification: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
      console.log('Initializing specification for', selectedCustomer, selectedJob.name);
      initializeSpecification();
    }
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  useEffect(() => {
    console.log('Top nodes updated:', topNodes);
  }, [topNodes]);

  useEffect(() => {
    console.log('Side nodes updated:', sideNodes);
  }, [sideNodes]);

  useEffect(() => {
    console.log('variableTree updated:', variableTree);
  }, [variableTree]);

  const isAxiosError = (error: any): error is { response: { status: number } } => {
    return error && error.response && typeof error.response.status === 'number';
  };

  const findVariable = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id.toString() === id) {
        return node;
      }
      if (node.children) {
        const found = findVariable(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const handleVariableExpand = async (nodeId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, nodeId);
      if (!node) {
        console.log('Node not found:', nodeId);
        return;
      }

      console.log('Expanding node:', node);

      // If we already have children, just toggle expanded state
      if (node.children && node.children.length > 0) {
        setExpandedVariableItems(prev => {
          const isCurrentlyExpanded = prev.includes(nodeId);
          return isCurrentlyExpanded 
            ? prev.filter(id => id !== nodeId)
            : [...prev, nodeId];
        });
        return;
      }

      setIsLoading(true);
      try {
        // For variables, fetch their codes
        if (node.type === 'Variable') {
          const nodeName = node.value1 || node.name || '';
          console.log('Fetching codes for variable:', nodeName);
          
          const variables = await carbonClient.getVarNodes(nodeName);
          if (variables && variables.length > 0 && variables[0].children) {
            const mappedChildren = variables[0].children.map(child => mapToExtendedGenNode(child));
            
            // Update the tree with codes
            setVariableTree(prev => {
              const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
                return nodes.map(item => {
                  if (item.id === nodeId) {
                    return {
                      ...item,
                      children: mappedChildren,
                      expandable: true,
                      isExpanded: true
                    };
                  }
                  if (item.children) {
                    return {
                      ...item,
                      children: updateNode(item.children)
                    };
                  }
                  return item;
                });
              };
              return updateNode(prev);
            });
          }
        }
        // For folders, they should already have their structure from initial load
        else if (node.type === 'Folder' || node.isFolder) {
          console.log('Expanding folder:', node.name);
          // Just mark as expanded since children should already be there
          setExpandedVariableItems(prev => [...prev, nodeId]);
        }

      } catch (error) {
        console.error('Error fetching children:', error);
        setErrorMessage(`Error fetching children: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error in handleVariableExpand:', error);
    }
  };

  const updateTreeWithChildVariables = (parentId: string, childVariables: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === parentId) {
            return {
              ...variable,
              children: childVariables.map(mapToExtendedGenNode),
              isExpanded: true
            }
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) }
          }
          return variable
        })
      }
      const updatedTree = updateVariables(prevTree);
      if (JSON.stringify(updatedTree) === JSON.stringify(prevTree)) {
        console.log('No changes in variableTree, skipping update');
        return prevTree;
      }
      console.log('Updating variableTree with new children');
      return updatedTree;
    })
  }

  const updateTreeWithCodeframe = (variableId: string, codeframe: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === variableId) {
            const codeNodes: ExtendedGenNode[] = codeframe[0].children?.map(code => ({
              ...code,
              id: `${variableId}_${code.id}`,
              type: 'Code',
              name: `${code.value1 || ''} - ${code.value2 || ''}`,
              isExpanded: false,
              children: undefined,
            })) || [];

            return { ...variable, children: codeNodes, isExpanded: true };
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) };
          }
          return variable;
        });
      };
      const updatedTree = updateVariables(prevTree);
      if (JSON.stringify(updatedTree) === JSON.stringify(prevTree)) {
        console.log('No changes in variableTree, skipping update');
        return prevTree;
      }
      console.log('Updating variableTree with codeframe');
      return updatedTree;
    });
  };

  const handleDragStart = async (event: React.DragEvent<HTMLLIElement>, node: ExtendedGenNode) => {
    // Only allow drag if this is the selected node and it's ready
    if (!selectedVariable || selectedVariable.id !== node.id || !isVariableReady) {
      event.preventDefault();
      return;
    }

    console.log('Drag started:', { node: selectedVariable });
    
    try {
      console.log('Drag node before serialization:', selectedVariable);
      
      const dragData: DragPayload = {
        nodeId: selectedVariable.id,
        treeType: 'variable',
        nodeType: selectedVariable.type,
        value1: selectedVariable.value1,
        value2: selectedVariable.value2,
        parentId: selectedVariable.parentId,
        meta: selectedVariable.meta || [],
        isExpanded: selectedVariable.isExpanded,
        isFolder: selectedVariable.isFolder,
        level: selectedVariable.level,
        anyChildren: selectedVariable.anyChildren,
        name: selectedVariable.name,
        children: selectedVariable.children || []
      };
      
      event.dataTransfer.setData('application/json', JSON.stringify(dragData));
      console.log('Drag data after serialization:', dragData);
    } catch (error) {
      console.error('Error in drag start:', error);
    }
  };

  // Consolidated findParentNode function that can handle both string IDs and nodes
  const findParentNode = (nodes: ExtendedGenNode[], target: string | ExtendedGenNode): ExtendedGenNode | null => {
    const targetId = typeof target === 'string' ? target : target.id;
    
    for (const node of nodes) {
      if (node.children?.some(child => child.id === targetId)) {
        return node;
      }
      if (node.children) {
        const found = findParentNode(node.children, targetId);
        if (found) return found;
      }
    }
    return null;
  };

  const renderTreeItems = (variables: ExtendedGenNode[], treeType: 'variable' | 'axis' | 'function'): ExtendedGenNode[] => {
    return variables.map(variable => {
      const baseNode = {
        ...variable,
        id: variable.id.toString(),
        label: variable.name || variable.value1 || '',
        expandable: variable.expandable,
        content: (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {getVariableIcon(variable)}
            <span>{variable.name || variable.value1 || ''}</span>
            {variable.type === 'Code' && variable.meta && variable.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        ),
      };

      if (variable.children) {
        return {
          ...baseNode,
          children: renderTreeItems(variable.children, treeType)
        };
      }

      return baseNode;
    });
  };

  const handleVariableSelect = async (node: ExtendedGenNode) => {
    console.log('Variable/Folder selected:', node);
    
    // If clicking the same node again, deselect it
    if (selectedVariable?.id === node.id) {
      setSelectedVariable(null);
      setIsVariableReady(false);
      return;
    }

    setSelectedVariable(node);
    setIsVariableReady(false);

    // Handle both Variables and Folders that don't have loaded children yet
    if ((node.type === 'Variable' || node.isFolder)) {
      try {
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log('Fetching children for:', { type: node.type, name: nodeName });
        const variables = await carbonClient?.getVarNodes(nodeName);
        
        if (variables?.[0]?.children) {
          const mappedChildren = variables[0].children.map(child => ({
            ...mapToExtendedGenNode(child),
            id: `${node.id}_${child.id}`,
            type: child.type || 'Code',
            name: `${child.value1 || ''} - ${child.value2 || ''}`,
            parentId: node.id,
            anyChildren: Boolean(child.anyChildren || (child.children && child.children.length > 0))
          }));

          // Update only this node in the tree
          const updatedNode: ExtendedGenNode = {
            ...node,
            children: mappedChildren,
            isExpanded: true,
            anyChildren: true
          };

          // Update the variable tree, only modifying the specific node
          setVariableTree(prev => {
            const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
              return nodes.map(item => {
                if (item.id === node.id) {
                  return updatedNode;
                }
                if (item.children) {
                  return {
                    ...item,
                    children: updateNode(item.children)
                  };
                }
                return item;
              });
            };
            return updateNode(prev);
          });

          setSelectedVariable(updatedNode);
        }
      } catch (error) {
        console.error('Error loading children:', error);
      }
    } else {
      // If we already have children, just update the selected state
      setSelectedVariable(node);
    }
    
    setIsVariableReady(true);
  };

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedVariableItems(nodeIds);
    
    // Only fetch children for newly expanded nodes
    const newlyExpanded = nodeIds.filter(id => !expandedVariableItems.includes(id));
    newlyExpanded.forEach(id => {
      const node = findVariable(variableTree, id);
      if (node && !node.children) {
        handleVariableExpand(id);
      }
    });
  }, [expandedVariableItems, variableTree]);

  const handleSelectedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    console.log('RichTreeViewPro selection changed:', nodeIds);
    setSelectedVariables(nodeIds);
    if (nodeIds.length > 0) {
      const selectedNode = findVariable(variableTree, nodeIds[0]);
      console.log('Selected node:', selectedNode);
      if (selectedNode) {
        handleVariableSelect(selectedNode);
      }
    }
  }, [variableTree]);

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const item = findVariable(variableTree, itemId);
    const isSelected = selectedVariable?.id === itemId;
    const canDrag = isSelected && isVariableReady;

    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            width: '100%',
            p: 0.5,
            borderRadius: 1,
            bgcolor: isSelected ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
            opacity: isSelected && !isVariableReady ? 0.7 : 1,
            cursor: canDrag ? 'grab' : 'pointer',
          }}>
            {getVariableIcon(item)}
            <Typography variant="body2" sx={{ mr: 1 }}>{label || item?.name || item?.value1 || ''}</Typography>
            {item?.type === 'Code' && item.meta && item.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        }
        draggable={canDrag}
        onDragStart={(event: React.DragEvent<HTMLLIElement>) => {
          event.stopPropagation();
          if (item && canDrag) {
            handleDragStart(event, item);
          } else {
            event.preventDefault();
          }
        }}
      />
    );
  });

  const getVariableIcon = (node: ExtendedGenNode | null) => {
    if (!node) return null;

    const hasPC = node.meta?.some((m: { key: string; value: string }) => m.key === 'PC');
    const iconName = hasPC ? `Node${node.type}PC` : `Node${node.type}`;
    const iconPath = `/assets/icons/${iconName}.png`;

    return (
      <Box 
        component="img"
        src={iconPath}
        alt={node.type}
        sx={{ 
          width: 20,
          height: 20,
          mr: 1,
          objectFit: 'contain'
        }}
      />
    );
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>, target: 'top' | 'side') => {
    event.preventDefault();
    console.log('Drop event occurred on target:', target);
    
    const data = event.dataTransfer.getData('text/plain');
    console.log('Dropped data:', data);
    
    if (!data) {
      console.error('No data received in drop event');
      setErrorMessage('Error: No data received from drag operation');
      return;
    }
    
    try {
      const { nodeId, treeType } = JSON.parse(data) as { nodeId: string, treeType: 'variable' | 'axis' | 'function' };
      console.log('Parsed drop data:', { nodeId, treeType, target });

      const node = findVariable(variableTree, nodeId);
      console.log('Found node:', node);

      if (!node) {
        console.log('No node found to drop');
        setErrorMessage('No item found for drop');
        return;
      }

      if (validateDrop(node, target, treeType)) {
        const nodesToAdd = await fetchNodesForDrop(node);
        
        if (nodesToAdd.length > 0) {
          if (target === 'top') {
            setTopNodes(prev => {
              const newNodes = [...prev, ...nodesToAdd];
              console.log('Updated top nodes after drop:', newNodes);
              return newNodes;
            });
          } else {
            setSideNodes(prev => {
              const newNodes = [...prev, ...nodesToAdd];
              console.log('Updated side nodes after drop:', newNodes);
              return newNodes;
            });
          }
          setErrorMessage(null);
          console.log(`Node(s) added to ${target} nodes:`, nodesToAdd);
        } else {
          console.log('No valid nodes to add after fetching');
          setErrorMessage('No valid nodes to add');
        }
      } else {
        console.log('Invalid drop');
        setErrorMessage(`Invalid drop: The selected item cannot be dropped in ${target}`);
      }
    } catch (error) {
      console.error('Error processing dropped data:', error);
      setErrorMessage(`Error processing dropped item: ${error instanceof Error ? error.message : String(error)}`);
    }
  };

  const mergeNodes = (existingNodes: ExtendedGenNode[], newNodes: ExtendedGenNode[]): ExtendedGenNode[] => {
    const result = [...existingNodes];

    newNodes.forEach(newNode => {
      const existingIndex = result.findIndex(n => n.id === newNode.id);
      if (existingIndex !== -1) {
        result[existingIndex] = {
          ...result[existingIndex],
          ...newNode,
          children: mergeNodes(result[existingIndex].children || [], newNode.children || [])
        };
      } else {
        const parentIndex = result.findIndex(n => n.id === newNode.parentId);
        if (parentIndex !== -1) {
          result[parentIndex] = {
            ...result[parentIndex],
            children: mergeNodes(result[parentIndex].children || [], [newNode])
          };
        } else {
          result.push(newNode);
        }
      }
    });

    return result;
  };

  const fetchNodesForDrop = async (node: ExtendedGenNode): Promise<ExtendedGenNode[]> => {
    if (!carbonClient || !isClientReady) {
      throw new Error('Carbon client is not ready');
    }

    let nodesToAdd: ExtendedGenNode[] = [];

    try {
      const nodeName = node.name || node.value1 || node.id.toString();
      console.log(`Fetching nodes for: ${nodeName}`);

      switch (node.type) {
        case 'Folder':
        case 'Variable':
        case 'Axis':
        case 'Codeframe':
          // For Folder, Variable, Axis, and Codeframe nodes, fetch their children
          const fetchedNodes = await carbonClient.getVarNodes(nodeName);
          if (fetchedNodes.length > 0) {
            nodesToAdd = [{
              ...fetchedNodes[0],
              id: fetchedNodes[0].id.toString(), // Ensure id is a string
              name: `${fetchedNodes[0].value1 || ''} ${fetchedNodes[0].value2 || ''}`.trim(),
              children: processNodes(fetchedNodes[0].children || [], fetchedNodes[0] as ExtendedGenNode),
              isExpanded: true
            } as ExtendedGenNode]; // Add type assertion here
          }
          break;
        case 'Function':
          // For Function nodes, add them directly
          nodesToAdd = [node];
          break;
        case 'Code':
        case 'Arith':
        case 'Net':
        case 'Stat':
        case 'Base':
          // For leaf code nodes, create a parent Codeframe with only this code
          const parentNode = findParentNode(variableTree, node);
          if (parentNode) {
            nodesToAdd = [{
              ...parentNode,
              children: [node],
              isExpanded: true
            }];
          } else {
            nodesToAdd = [node];
          }
          break;
        default:
          console.warn(`Unhandled node type: ${node.type}`);
          nodesToAdd = [node];
      }
    } catch (error) {
      console.error('Error fetching nodes for drop:', error);
      nodesToAdd = [node];
    }

    return nodesToAdd;
  };

  const validateDrop = (node: ExtendedGenNode, target: 'top' | 'side', treeType: 'variable' | 'axis' | 'function'): boolean => {
    const validTypes = [
      'Folder', 'Variable', 'Axis', 'Function', 'Code', 'Arith', 'Net', 'Stat', 'Base',
      'Codeframe', 'Level', 'Filter', 'Weight', 'Spacer', 'TabAxis'
    ];
  
    return validTypes.includes(node.type);
  };

  const handleMoveUp = (target: 'top' | 'side', parentId: string | null, index: number) => {
    const updateNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
      if (parentId) {
        return nodes.map(node => {
          if (node.id.toString() === parentId && node.children) {
            const newChildren = [...node.children];
            if (index > 0) {
              [newChildren[index - 1], newChildren[index]] = [newChildren[index], newChildren[index - 1]];
            }
            return { ...node, children: newChildren };
          }
          if (node.children) {
            return { ...node, children: updateNodes(node.children) };
          }
          return node;
        });
      } else {
        const newNodes = [...nodes];
        if (index > 0) {
          [newNodes[index - 1], newNodes[index]] = [newNodes[index], newNodes[index - 1]];
        }
        return newNodes;
      }
    };

    if (target === 'top') {
      setTopNodes(updateNodes);
    } else {
      setSideNodes(updateNodes);
    }
  }

  const handleMoveDown = (target: 'top' | 'side', parentId: string | null, index: number) => {
    const updateNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
      if (parentId) {
        return nodes.map(node => {
          if (node.id.toString() === parentId && node.children) {
            const newChildren = [...node.children];
            if (index < newChildren.length - 1) {
              [newChildren[index], newChildren[index + 1]] = [newChildren[index + 1], newChildren[index]];
            }
            return { ...node, children: newChildren };
          }
          if (node.children) {
            return { ...node, children: updateNodes(node.children) };
          }
          return node;
        });
      } else {
        const newNodes = [...nodes];
        if (index < newNodes.length - 1) {
          [newNodes[index], newNodes[index + 1]] = [newNodes[index + 1], newNodes[index]];
        }
        return newNodes;
      }
    };

    if (target === 'top') {
      setTopNodes(updateNodes);
    } else {
      setSideNodes(updateNodes);
    }
  }

  const handleDelete = (target: 'top' | 'side', parentId: string | null, index: number) => {
    setNodeToDelete({ target, parentId, index });
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    if (!nodeToDelete) return;
    
    const { target, parentId, index } = nodeToDelete;
    const updateNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
      if (parentId) {
        return nodes.map(node => {
          if (node.id.toString() === parentId && node.children) {
            const newChildren = node.children.filter((_, i) => i !== index);
            return { ...node, children: newChildren };
          }
          if (node.children) {
            return { ...node, children: updateNodes(node.children) };
          }
          return node;
        });
      } else {
        return nodes.filter((_, i) => i !== index);
      }
    };

    if (target === 'top') {
      setTopNodes(updateNodes);
    } else {
      setSideNodes(updateNodes);
    }
    
    setIsDeleteDialogOpen(false);
    setNodeToDelete(null);
  };

  const runSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready')
      return
    }

    try {
      setIsLoading(true);

      const specData: RunSpecRequest = {
        name: reportTitle || 'Untitled Report', // Use the report title here
        dProps: {
          titles: {
            name: { font: { name: 'Arial', size: 12 }, visible: true },
            top: { font: { name: 'Arial', size: 12 }, visible: true },
            side: { font: { name: 'Arial', size: 12 }, visible: true },
            filter: { font: { name: 'Arial', size: 12 }, visible: true },
            weight: { font: { name: 'Arial', size: 12 }, visible: true },
            status: { font: { name: 'Arial', size: 12 }, visible: true },
            labelling: { name: true, desc: true, script: true, codes: true }
          },
          columns: {
            groups: { font: { name: 'Arial', size: 12 }, visible: true, size: 0 },
            labels: { font: { name: 'Arial', size: 12 }, visible: true, width: 100, height: 30 },
            letters: { font: { name: 'Arial', size: 12 } },
            sort: { active: false, increasing: true, ungrouped: false, band: 0, type: 0, key: 0 },
            hide: { active: false, missing: false, empty: false, vectors: '' },
            baseCount: true
          },
          rows: {
            groups: { font: { name: 'Arial', size: 12 }, visible: true, size: 0 },
            labels: { font: { name: 'Arial', size: 12 }, visible: true, width: 100, height: 30 },
            letters: { font: { name: 'Arial', size: 12 } },
            sort: { active: false, increasing: true, ungrouped: false, band: 0, type: 0, key: 0 },
            hide: { active: false, missing: false, empty: false, vectors: '' },
            baseCount: true
          },
          cells: {
            key: { font: { name: 'Arial', size: 12 }, visible: true },
            bases: { font: { name: 'Arial', size: 12 } },
            frequencies: { font: { name: 'Arial', size: 12 }, visible: true },
            columnPercents: { font: { name: 'Arial', size: 12 }, visible: true },
            rowPercents: { font: { name: 'Arial', size: 12 }, visible: true },
            stat1: { font: { name: 'Arial', size: 12 }, visible: true },
            stat2: { font: { name: 'Arial', size: 12 }, visible: true },
            percentSign: { visible: true },
            missingAsZero: false,
            missingAsBlank: false,
            zeroAsBlank: false,
            blankAsChar: false,
            blankChar: ' ',
            percentsAsProportions: false,
            showRedundant100: false
          },
          significance: {
            visible: false,
            type: 0,
            propStat: 0,
            meanStat: 0,
            statsParam: '',
            letterSequence: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            letters: 0,
            appendLetters: false,
            headcount: 0,
            meanPoolVariance: false,
            propPooledEst: false,
            continuityCorr: false,
            skipBase30: false,
            skipCell5: false,
            letters64: false,
            oneTailed: false,
            hiLoTest: false,
            sigLevel1: { font: { name: 'Arial', size: 12 }, threshold: 0.05 },
            sigLevel2: { font: { name: 'Arial', size: 12 }, threshold: 0.01 },
            sigLevel3: { font: { name: 'Arial', size: 12 }, threshold: 0.001 }
          },
          decimals: {
            frequencies: 0,
            percents: 0,
            statistics: 2,
            expressions: 2
          },
          output: {
            format: 0
          },
          corner: {
            priority: 0
          }
        },
        spec: {
          topAxis: topNodes.map(mapNodeToGenNode),
          sideAxis: sideNodes.map(mapNodeToGenNode),
          topLock: currentSpec?.spec?.topLock || false,
          sideLock: currentSpec?.spec?.sideLock || false,
          useFilter: filter !== '',
          filter: filter,
          useWeight: weight !== '',
          weight: weight,
          specProperties: currentSpec?.spec?.specProperties || {
            caseFilter: null,
            initAsMissing: false,
            excludeNE: false,
            padHierarchics: false,
            arithOverStats: false,
            topInsert: null,
            sideInsert: null,
            level: null,
            fullStats: false // Add this line
          }
        }
      }

      console.log('Running spec with data:', JSON.stringify(specData, null, 2));
      
      console.log('Attempting to run spec...');

      const response = await carbonClient.runSpec(specData);
      console.log('Spec run response:', response);
      
      setRunSpecResponse(response);
      setIsRunSpecModalOpen(true);

      // The spec is now saved in localStorage by the runSpec method in CarbonClient
      
      // Navigate to the Report page
      navigate('/report', { state: { updatedSpec: true } });
    } catch (error) {
      console.error('Error running spec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          config: error.config
        });
        setErrorMessage(`Error running spec: ${error.message}. Status: ${error.response?.status}. Data: ${JSON.stringify(error.response?.data)}`);
      } else {
        setErrorMessage(`Error running spec: ${(error as Error).message}`);
      }
      console.log('Current spec:', currentSpec);
      console.log('Top nodes:', topNodes);
      console.log('Side nodes:', sideNodes);
    } finally {
      setIsLoading(false);
    }
  }

  const renderNode = (node: ExtendedGenNode, index: number, target: 'top' | 'side', parentNode: ExtendedGenNode | null = null) => {
    if (!node) {
      console.error(`Attempted to render undefined node at index ${index} for ${target}`);
      return null;
    }

    const nodes = target === 'top' ? topNodes : sideNodes;
    let siblings: ExtendedGenNode[] = parentNode ? parentNode.children || [] : nodes;
    let nodeIndex = siblings.findIndex(n => n.id.toString() === node.id.toString());

    const isFirst = nodeIndex === 0;
    const isLast = nodeIndex === siblings.length - 1;
    const isSingle = siblings.length === 1;

    // Format the node name
    const formattedName = node.type === 'Code' 
      ? `${node.value1 || ''} - ${node.value2 || ''}`
      : node.name || node.value1 || node.value2 || '';

    return (
      <Box key={node.id.toString() || `${target}-${index}`}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 1, 
            ml: parentNode ? 3 : 0,
            bgcolor: (target === 'top' ? selectedTopNode : selectedSideNode)?.id === node.id ? 'lightblue' : 'transparent',
            cursor: 'pointer'
          }}
          onClick={() => target === 'top' ? setSelectedTopNode(node) : setSelectedSideNode(node)}
        >
          {getVariableIcon(node)}
          <Typography sx={{ mr: 1 }}>{formattedName}</Typography>
          {node.isPC && <Percent fontSize="small" sx={{ mr: 1 }} />}
          {node.isBase && <Typography variant="caption" sx={{ mr: 1 }}>(Base)</Typography>}
          <IconButton 
            onClick={(e) => { e.stopPropagation(); handleMoveUp(target, parentNode ? parentNode.id.toString() : null, nodeIndex); }} 
            disabled={isSingle || isFirst}
            size="small"
          >
            <ArrowUpward fontSize="small" />
          </IconButton>
          <IconButton 
            onClick={(e) => { e.stopPropagation(); handleMoveDown(target, parentNode ? parentNode.id.toString() : null, nodeIndex); }} 
            disabled={isSingle || isLast}
            size="small"
          >
            <ArrowDownward fontSize="small" />
          </IconButton>
          <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(target, parentNode ? parentNode.id.toString() : null, nodeIndex); }} size="small">
            <Delete fontSize="small" />
          </IconButton>
        </Box>
        {node.children && node.children.length > 0 && (
          <Box sx={{ ml: 3 }}>
            {node.children.map((childNode, childIndex) => renderNode(childNode, childIndex, target, node))}
          </Box>
        )}
      </Box>
    )
  }

  const handleNest = (target: 'top' | 'side') => {
    const selectedNode = target === 'top' ? selectedTopNode : selectedSideNode;
    const nodes = target === 'top' ? topNodes : sideNodes;
    if (selectedNode) {
      const index = nodes.findIndex(node => node.id === selectedNode.id);
      if (index > 0) {
        const newNodes = [...nodes];
        newNodes[index - 1] = {
          ...newNodes[index - 1],
          children: [...(newNodes[index - 1].children || []), newNodes[index]]
        };
        newNodes.splice(index, 1);
        target === 'top' ? setTopNodes(newNodes) : setSideNodes(newNodes);
      }
    }
  };

  const handleSetPercentage = (target: 'top' | 'side') => {
    const selectedNode = target === 'top' ? selectedTopNode : selectedSideNode;
    if (selectedNode) {
      console.log('Current node state:', selectedNode);
      const newNodes = (target === 'top' ? topNodes : sideNodes).map((node: ExtendedGenNode) => {
        if (node.id === selectedNode.id) {
          const newIsPC = !node.isPC;
          console.log('Toggling isPC from', node.isPC, 'to', newIsPC);
          const newMeta = newIsPC 
            ? [...(node.meta || []).filter((m: { key: string; value: string }) => m.key !== 'PC'), { key: 'PC', value: '1' }]
            : (node.meta || []).filter((m: { key: string; value: string }) => m.key !== 'PC');
          console.log('New meta:', newMeta);
          const updatedNode = { ...node, isPC: newIsPC, meta: newMeta };
          console.log('Updated node:', updatedNode);
          return updatedNode;
        }
        return node;
      });
      console.log('New nodes state:', newNodes);
      target === 'top' ? setTopNodes(newNodes) : setSideNodes(newNodes);
      
      // Force a preview table update
      handlePreviewTable();
    }
  };

  const handleSetBase = (target: 'top' | 'side') => {
    const selectedNode = target === 'top' ? selectedTopNode : selectedSideNode;
    if (selectedNode) {
      const newNodes = (target === 'top' ? topNodes : sideNodes).map((node: ExtendedGenNode) =>
        node.id === selectedNode.id ? { ...node, isBase: true } : { ...node, isBase: false }
      );
      target === 'top' ? setTopNodes(newNodes) : setSideNodes(newNodes);
    }
  };

  const fetchNodeCodes = async (nodeId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, nodeId);
      if (node) {
        console.log(`Fetching variables for node:`, node);
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log(`Using node name/id: ${nodeName}`);
        
        if (node.children && node.children.length > 0) {
          console.log(`Node has children, displaying them as variables`);
          setSelectedNodeVariables(node.children.map(child => child.name || child.value1 || '').filter(Boolean));
          setErrorMessage(null);
        } else {
          const variables = await carbonClient.getVarNodes(nodeName);
          console.log(`Fetched codes for ${nodeName}:`, variables);
          if (variables.length === 0) {
            console.log(`No codes found for ${nodeName} (ID: ${nodeId})`);
            setSelectedNodeVariables([]);
            setErrorMessage(`No codes found for ${nodeName}`);
          } else {
            setSelectedNodeVariables(variables.map(v => v.name || v.value1 || '').filter(Boolean));
            setErrorMessage(null);
          }
        }
      } else {
        console.error(`Node not found for ID: ${nodeId}`);
        setErrorMessage(`Node not found for ID: ${nodeId}`);
      }
    } catch (error) {
      console.error('Error fetching node variables:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);
        setErrorMessage(`Error fetching node variables: ${errorMessage}`);
      } else if (error instanceof Error) {
        setErrorMessage(`Error fetching node variables: ${error.message}`);
      } else {
        setErrorMessage(`Unexpected error: ${String(error)}`);
      }
    }
  };

  useEffect(() => {
    console.log('Top nodes updated:', topNodes);
  }, [topNodes]);

  useEffect(() => {
    console.log('Side nodes updated:', sideNodes);
  }, [sideNodes]);

  const mapNodeToGenNode = (node: ExtendedGenNode): GenNode => ({
    type: node.type,
    id: node.id.toString(),
    parentId: node.parentId?.toString() || null,
    isExpanded: node.isExpanded,
    isFolder: node.isFolder,
    value1: node.value1 || '',
    value2: node.value2 || '',
    meta: node.meta || [],
    level: node.level || 0,
    anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
    children: node.children ? node.children.map(mapNodeToGenNode) : null,
    name: node.name || node.value1 || '',
    isPC: node.isPC || false,
    isBase: node.isBase || false
  });

  const handleValidateSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    console.log('CarbonClient:', carbonClient);
    console.log('CarbonClient methods:', Object.getOwnPropertyNames(Object.getPrototypeOf(carbonClient)));
    console.log('validateSpec method:', carbonClient.validateSpec);

    if (typeof carbonClient.validateSpec !== 'function') {
      setErrorMessage('validateSpec method is not available on carbonClient');
      return;
    }

    try {
      setIsLoading(true);

      const specData: RunSpecRequest['spec'] = {
        topAxis: topNodes.map(mapNodeToGenNode),
        sideAxis: sideNodes.map(mapNodeToGenNode),
        topLock: currentSpec?.spec?.topLock || false,
        sideLock: currentSpec?.spec?.sideLock || false,
        useFilter: filter !== '',
        filter: filter,
        useWeight: weight !== '',
        weight: weight,
        specProperties: currentSpec?.spec?.specProperties || {
          caseFilter: null,
          initAsMissing: false,
          excludeNE: false,
          padHierarchics: false,
          arithOverStats: false,
          topInsert: null,
          sideInsert: null,
          level: null,
          fullStats: false // Add this line
        }
      };

      console.log('Validating spec with data:', JSON.stringify(specData, null, 2));
      
      console.log('Attempting to validate spec...');

      const response = await carbonClient.validateSpec(specData);
      console.log('Spec validation response:', response);
      
      setValidateResponse(response);
      setIsValidateModalOpen(true);
    } catch (error) {
      console.error('Error validating spec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          config: error.config
        });
        setErrorMessage(`Error validating spec: ${error.message}. Status: ${error.response?.status}. Data: ${JSON.stringify(error.response?.data)}`);
      } else {
        setErrorMessage(`Error validating spec: ${(error as Error).message}`);
      }
      console.log('Current spec:', currentSpec);
      console.log('Top nodes:', topNodes);
      console.log('Side nodes:', sideNodes);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentSpec = (): RunSpecRequest => ({
    name: reportTitle || 'Untitled Report',
    dProps: currentSpec?.dProps || {}, // You might want to update this with the actual current dProps
    spec: {
      topAxis: topNodes.map(mapNodeToGenNode),
      sideAxis: sideNodes.map(mapNodeToGenNode),
      topLock: currentSpec?.spec?.topLock || false,
      sideLock: currentSpec?.spec?.sideLock || false,
      useFilter: filter !== '',
      filter: filter,
      useWeight: weight !== '',
      weight: weight,
      specProperties: currentSpec?.spec?.specProperties || {
        caseFilter: null,
        initAsMissing: false,
        excludeNE: false,
        padHierarchics: false,
        arithOverStats: false,
        topInsert: null,
        sideInsert: null,
        level: null,
        fullStats: false
      }
    }
  });

  const handleShowCurrentSpec = () => {
    setIsShowSpecModalOpen(true);
  };

  useEffect(() => {
    const state = location.state as { loadedSpec?: RunSpecRequest, reportName?: string } | null;
    if (state?.loadedSpec) {
      loadSpec(state.loadedSpec);
      if (state.reportName) {
        setReportTitle(state.reportName);
      }
    }
  }, [location.state]);

  // Add this before the return statement
  useEffect(() => {
    console.log('Variable Tree Structure:');
    variableTree.forEach((node, index) => {
      console.log(`Node ${index + 1}:`, {
        id: node.id,
        type: node.type,
        name: node.name,
        value1: node.value1,
        value2: node.value2,
        anyChildren: node.anyChildren,
        isExpanded: node.isExpanded,
        children: node.children?.length || 0
      });
    });
  }, [variableTree]);

  const getNodeTypeIcon = (type: string, isFolder: boolean = false) => {
    if (isFolder) return <Folder fontSize="small" sx={{ mr: 1, color: 'lightblue' }} />;

    switch (type) {
      case 'TabAxis':
        return <TableRows fontSize="small" sx={{ mr: 1, color: '#9c27b0' }} />; // Purple for structure
      case 'Codeframe':
        return <DataArray fontSize="small" sx={{ mr: 1, color: '#2196f3' }} />; // Blue for data containers
      case 'Code':
        return <Label fontSize="small" sx={{ mr: 1, color: '#ff9800' }} />; // Orange for codes
      case 'Filter':
        return <FilterAlt fontSize="small" sx={{ mr: 1, color: '#4caf50' }} />; // Green for filters
      case 'Function':
        return <Functions fontSize="small" sx={{ mr: 1, color: '#f44336' }} />; // Red for functions
      case 'Spacer':
        return <Crop169 fontSize="small" sx={{ mr: 1, color: '#757575' }} />; // Grey for spacers, using Crop169 instead of Space
      case 'Base':
        return <Calculate fontSize="small" sx={{ mr: 1, color: '#009688' }} />; // Teal for base calculations
      case 'Variable':
        return <Circle fontSize="small" sx={{ mr: 1, color: '#e91e63' }} />; // Pink for variables
      default:
        return <ListIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;
    }
  };

  const handlePreviewTable = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      setIsLoading(true);
      
      console.log('Generating preview with nodes:', { topNodes, sideNodes });
      
      // Create a temporary spec for preview
      const previewSpec: RunSpecRequest = {
        name: 'Preview',
        dProps: {},
        spec: {
          topAxis: topNodes.map(node => {
            const mappedNode = mapNodeToGenNode(node);
            console.log('Mapped node for preview:', { original: node, mapped: mappedNode });
            return mappedNode;
          }),
          sideAxis: sideNodes.map(node => {
            const mappedNode = mapNodeToGenNode(node);
            console.log('Mapped node for preview:', { original: node, mapped: mappedNode });
            return mappedNode;
          }),
          topLock: false,
          sideLock: false,
          useFilter: filter !== '',
          filter: filter,
          useWeight: weight !== '',
          weight: weight,
          specProperties: {
            caseFilter: null,
            initAsMissing: false,
            excludeNE: false,
            padHierarchics: false,
            arithOverStats: false,
            topInsert: null,
            sideInsert: null,
            level: null,
            fullStats: false
          }
        }
      };

      // Run the spec to generate the preview
      await carbonClient.runSpec(previewSpec);
      
      // Generate the HTML table
      const result = await carbonClient.getReportFormatHtml();
      
      if (!result) {
        throw new Error('Failed to generate HTML report');
      }

      // Handle both string and string array responses
      const htmlString = Array.isArray(result) ? result.join('') : result;

      // Clean the HTML
      const cleanedHtml = htmlString
        .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '')
        .replace(/\s*style="[^"]*"/gi, '');

      setPreviewHtml(cleanedHtml);
      setIsPreviewModalOpen(true);
    } catch (error) {
      console.error('Error previewing table:', error);
      setErrorMessage(`Error previewing table: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Add this helper function
  const isNodeEditable = (node: ExtendedGenNode): boolean => {
    return node.type === 'Node' || node.type === 'Code';
  };

  const handleSaveSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      setIsLoading(true);
      
      const saveSpec: RunSpecRequest = {
        name: newTableName,
        dProps: {},
        spec: {
          topAxis: topNodes.map(mapNodeToGenNode),
          sideAxis: sideNodes.map(mapNodeToGenNode),
          topLock: false,
          sideLock: false,
          useFilter: filter !== '',
          filter: filter,
          useWeight: weight !== '',
          weight: weight,
          specProperties: {
            caseFilter: null,
            initAsMissing: false,
            excludeNE: false,
            padHierarchics: false,
            arithOverStats: false,
            topInsert: null,
            sideInsert: null,
            level: null,
            fullStats: false
          }
        }
      };

      // First run the spec to ensure it's valid
      await carbonClient.runSpec(saveSpec);
      
      // Then save it using the report/save endpoint
      // Using empty string as sub (folder) parameter since we're saving at root level
      await carbonClient.saveReport(newTableName, '');
      
      setIsSaveDialogOpen(false);
      setNewTableName('');
      setErrorMessage(null);
    } catch (error) {
      console.error('Error saving specification:', error);
      setErrorMessage(`Error saving specification: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <Box sx={{ 
        display: 'flex', 
        height: 'calc(100vh - 84px)',
        overflow: 'hidden',
        pl: 2,
        pr: 3,
        py: 2,
      }}>
        {/* Left sidebar - Variable Tree */}
        <Paper sx={{ 
          width: 260, 
          p: 2, 
          mr: 2,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h6" gutterBottom>Variable Tree</Typography>
          {isLoading ? (
            <Typography>Loading variable tree...</Typography>
          ) : errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : variableTree.length > 0 ? (
            <RichTreeViewPro<ExtendedGenNode, true>
              items={renderTreeItems(variableTree, 'variable')}
              slots={{
                item: CustomTreeItem,
                expandIcon: () => <ExpandMore fontSize="small" />,
                collapseIcon: () => <ExpandLess fontSize="small" />,
              }}
              expandedItems={expandedVariableItems}
              selectedItems={selectedVariables}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect={true}
              draggable="true"
              sx={{
                '.MuiTreeItem-root': {
                  padding: 0,
                },
                '.MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '.MuiTreeItem-iconContainer': {
                  width: 20,
                  marginRight: 0,
                  padding: '0 4px',
                },
                '.MuiTreeItem-group': {
                  marginLeft: '20px',
                  padding: 0,
                }
              }}
            />
          ) : (
            <Typography>No variables found</Typography>
          )}
        </Paper>

        {/* Right side - Specification content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          {/* Top toolbar */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="contained" onClick={handleValidateSpec} disabled={isLoading}>
                  Validate Spec
                </Button>
                <Button variant="contained" onClick={handleShowCurrentSpec} disabled={isLoading}>
                  Show Current Spec
                </Button>
                <Button 
                  variant="contained" 
                  onClick={handlePreviewTable} 
                  disabled={isLoading || !topNodes.length || !sideNodes.length}
                  startIcon={<TableRows />}
                >
                  Preview Table
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setIsSaveDialogOpen(true)}
                  disabled={isLoading}
                >
                  Save Table
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, maxWidth: '50%', justifyContent: 'flex-end' }}>
                <TextField
                  label="Filter"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
                <TextField
                  label="Weight"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
              </Box>
            </Box>
          </Paper>

          {/* Main content area */}
          <Paper sx={{ 
            flex: 1, 
            p: 2, 
            overflowY: 'auto', 
            display: 'flex',
            flexDirection: 'column'
          }}>
            {/* Report Title */}
            <TextField
              label="Report Title"
              value={reportTitle}
              onChange={(e) => setReportTitle(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ mb: 2 }}
            />

            {/* New NodeTreeEditor components */}
            <Box sx={{ display: 'flex', mt: 4, height: '400px', width: '100%' }}>
              <Box sx={{ width: '50%', pr: 1 }}>
                <Typography variant="h6" gutterBottom>Top Node Editor</Typography>
                <Box sx={{ height: 'calc(100% - 32px)', overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px' }}>
                  <NodeTreeEditor
                    initialItems={topNodes}
                    onItemsChange={(newItems) => {
                      console.log('New Top Items:', newItems);
                      setTopNodes(newItems as ExtendedGenNode[]);
                    }}
                    isLoading={isLoading}
                  />
                </Box>
              </Box>
              <Box sx={{ width: '50%', pl: 1 }}>
                <Typography variant="h6" gutterBottom>Side Node Editor</Typography>
                <Box sx={{ height: 'calc(100% - 32px)', overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px' }}>
                  <NodeTreeEditor
                    initialItems={sideNodes}
                    onItemsChange={(newItems) => {
                      console.log('New Side Items:', newItems);
                      setSideNodes(newItems as ExtendedGenNode[]);
                    }}
                    isLoading={isLoading}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Validate Spec Modal */}
      <Modal
        open={isValidateModalOpen}
        onClose={() => setIsValidateModalOpen(false)}
        aria-labelledby="validate-spec-modal"
        aria-describedby="validate-spec-response"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="validate-spec-modal" variant="h6" component="h2">
            Spec Validation Result
          </Typography>
          <Typography id="validate-spec-response" sx={{ mt: 2 }}>
            {validateResponse ? JSON.stringify(validateResponse, null, 2) : 'No response'}
          </Typography>
        </Box>
      </Modal>

      {/* Show Current Spec Modal */}
      <Dialog
        open={isShowSpecModalOpen}
        onClose={() => setIsShowSpecModalOpen(false)}
        aria-labelledby="show-spec-modal"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="show-spec-modal">Current Specification</DialogTitle>
        <DialogContent>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {JSON.stringify(getCurrentSpec(), null, 2)}
          </pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsShowSpecModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Preview Table Modal */}
      <Modal
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
        aria-labelledby="preview-table-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflow: 'auto',
          borderRadius: 1,
        }}>
          <IconButton
            onClick={() => setIsPreviewModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
          
          <Typography variant="h6" component="h2" gutterBottom>
            Table Preview
          </Typography>
          
          {previewHtml && (
            <Box sx={{
              '& table': {
                width: '100%',
                borderCollapse: 'collapse',
                '& td, & th': {
                  border: '1px solid #ddd',
                  padding: '8px',
                  fontSize: '14px'
                },
                '& tr:nth-of-type(even)': {
                  backgroundColor: '#f5f5f5'
                }
              },
              mb: 7 // Add margin at bottom for the close button
            }}>
              <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
            </Box>
          )}

          <Box sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            display: 'flex',
            gap: 1
          }}>
            <Button
              variant="contained"
              onClick={() => setIsPreviewModalOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Save Dialog */}
      <Dialog open={isSaveDialogOpen} onClose={() => setIsSaveDialogOpen(false)}>
        <DialogTitle>Save Table</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Table Name"
            fullWidth
            value={newTableName}
            onChange={(e) => setNewTableName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleSaveSpec} 
            variant="contained" 
            disabled={!newTableName.trim()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  )
}
