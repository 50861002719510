import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Tables } from './pages/Tables';
import { Specification } from './pages/Specification';
import { Files } from './pages/Files';
import { CoPilot } from './pages/CoPilot';
import { Experiments } from './pages/Experiments';
export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/tables" element={<Tables />} />
      <Route path="/specification" element={<Specification />} />
      <Route path="/files" element={<Files />} />
      <Route path="/copilot" element={<CoPilot />} />
      <Route path="/experiments" element={<Experiments />} />
    </Routes>
  );
}; 