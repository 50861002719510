import React, { createContext, useContext, useState, useEffect } from 'react';
import { CarbonClient } from '../services/CarbonClient';
import { SessionInfo, User } from '../types/types';

export interface AuthContextType {
  isAuthenticated: boolean;
  isClientReady: boolean;
  carbonClient: CarbonClient | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  user: User | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isClientReady, setIsClientReady] = useState(false);
  const [carbonClient, setCarbonClient] = useState<CarbonClient | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const storedSession = localStorage.getItem('sessionInfo');
    if (storedSession) {
      const sessionInfo: SessionInfo = JSON.parse(storedSession);
      const client = new CarbonClient();
      client.setSessionInfo(sessionInfo);
      setCarbonClient(client);
      setIsAuthenticated(true);
      setIsClientReady(true);
      setUser({ name: sessionInfo.name, sessionId: sessionInfo.sessionId });
    }
  }, []);

  const login = async (username: string, password: string) => {
    try {
      if (!carbonClient) {
        const newClient = new CarbonClient();
        setCarbonClient(newClient);
      }

      const client = carbonClient || new CarbonClient();
      const sessionInfo = await client.authenticate(username, password);
      
      localStorage.setItem('sessionInfo', JSON.stringify(sessionInfo));
      client.setSessionInfo(sessionInfo);
      setCarbonClient(client);
      setIsAuthenticated(true);
      setIsClientReady(true);
      setUser({ name: sessionInfo.name, sessionId: sessionInfo.sessionId });
    } catch (error) {
      console.error('Login failed:', error);
      setError('Login failed. Please check your credentials and try again.');
    }
  };

  const logout = async () => {
    if (carbonClient) {
      try {
        await carbonClient.closeJob();
        console.log('Closed current job');
        await carbonClient.logoff();
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
    setCarbonClient(null);
    setIsAuthenticated(false);
    setIsClientReady(false);
    
    localStorage.removeItem('sessionInfo');
    setUser(null);
  };

  const value = {
    isAuthenticated,
    isClientReady,
    carbonClient,
    login,
    logout,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};