import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, Alert } from '@mui/material';
import { licensingClient } from '../services/LicensingClient';
import { ResetPasswordRequest } from '../types/types';

interface ResetPasswordModalProps {
  open: boolean;
  onClose: () => void;
}

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    console.log('Attempting to reset password for email:', email);
    
    try {
      const request: ResetPasswordRequest = {
        email,
        time: new Date().toISOString(),
        signature: 0 // This will be handled server-side now
      };
      console.log('Sending reset password request:', request);
      
      const response = await licensingClient.resetPassword(request);
      console.log('Reset password response:', response);
      
      setIsSubmitted(true);
    } catch (err) {
      console.error('Error resetting password:', err);
      setError('Failed to send reset password request. Please try again.');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        {!isSubmitted ? (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Reset Password
            </Typography>
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                required
                type="email"
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
              >
                Reset Password
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Check Your Email
            </Typography>
            <Typography>
              If an account exists for the email address you entered, you will receive password reset instructions shortly.
            </Typography>
            <Button
              fullWidth
              onClick={onClose}
              variant="contained"
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
}; 