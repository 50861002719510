import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Button, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, FormControl, InputLabel, Select, Snackbar, Alert, Toolbar, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Breadcrumbs, ListItemIcon } from '@mui/material';
import { CloudUpload, GetApp, CreateNewFolder, Edit, Delete, MoreVert, Folder, InsertDriveFile, ArrowBack, Sort, Search, FileCopy, NavigateNext, PictureAsPdf, Image, Description, Code, TableChart, Archive, AudioFile, VideoFile, TextSnippet } from '@mui/icons-material';
import { useJob } from '../contexts/JobContext';
import { useCarbonClient } from '../hooks/useCarbonClient';
import 'bootstrap-icons/font/bootstrap-icons.css';

interface FileInfo {
  name: string;
  displayName: string;
  size?: number;
  lastModified?: Date;
  isFolder: boolean;
  parentFolder: string | null;
}

interface CustomerInfo {
  name: string;
  storageKey: string;
}

interface RenameFileDialog {
  open: boolean;
  oldName: string;
  newName: string;
  folderPath: string;
}

type SortField = 'name' | 'createdAt';
type SortOrder = 'asc' | 'desc';

interface SortConfig {
  field: SortField;
  order: SortOrder;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const formatFileSize = (bytes: number | undefined): string => {
  if (bytes === undefined) return 'Unknown';
  const mb = bytes / (1024 * 1024);
  if (mb < 0.1) return '< 0.1 MB';
  return `${mb.toFixed(1)} MB`;
};

const getFileIcon = (fileName: string) => {
  if (!fileName) return <i className="bi bi-file-earmark text-muted" style={{ fontSize: '1.2rem' }} />;
  
  const extension = fileName.toLowerCase().split('.').pop() || '';
  
  switch (extension) {
    // Images
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'webp':
      return <i className="bi bi-file-earmark-image text-primary" style={{ fontSize: '1.2rem' }} />;
    
    // Documents
    case 'pdf':
      return <i className="bi bi-file-earmark-pdf text-danger" style={{ fontSize: '1.2rem' }} />;
    case 'doc':
    case 'docx':
      return <i className="bi bi-file-earmark-word text-primary" style={{ fontSize: '1.2rem' }} />;
    
    // Spreadsheets
    case 'xls':
    case 'xlsx':
    case 'csv':
      return <i className="bi bi-file-earmark-excel text-success" style={{ fontSize: '1.2rem' }} />;
    
    case 'ppt':
    case 'pptx':
      return <i className="bi bi-file-earmark-ppt text-primary" style={{ fontSize: '1.2rem' }} />;
    
    // Code
    case 'json':
    case 'xml':
    case 'html':
    case 'css':
    case 'js':
    case 'ts':
    case 'py':
    case 'java':
    case 'cpp':
      return <i className="bi bi-file-earmark-code text-secondary" style={{ fontSize: '1.2rem' }} />;
    
    // Archives
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
      return <i className="bi bi-file-earmark-zip text-warning" style={{ fontSize: '1.2rem' }} />;
    
    // Audio
    case 'mp3':
    case 'wav':
    case 'ogg':
    case 'm4a':
      return <i className="bi bi-file-earmark-music text-info" style={{ fontSize: '1.2rem' }} />;
    
    // Video
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'mkv':
      return <i className="bi bi-file-earmark-play text-danger" style={{ fontSize: '1.2rem' }} />;
    
    // Text
    case 'txt':
    case 'log':
    case 'md':
      return <i className="bi bi-file-earmark-text text-info" style={{ fontSize: '1.2rem' }} />;
    
    // Default
    default:
      return <i className="bi bi-file-earmark text-muted" style={{ fontSize: '1.2rem' }} />;
  }
};

const getSortArrow = (field: SortField, currentSort: SortConfig) => {
  if (currentSort.field !== field) return null;
  return (
    <span style={{ fontSize: '1.2rem', marginLeft: '4px' }}>
      {currentSort.order === 'asc' ? '↑' : '↓'}
    </span>
  );
};

export const Files: React.FC = () => {
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedCustomer, selectedJob } = useJob();
  const carbonClient = useCarbonClient();

  const [newFolderName, setNewFolderName] = useState('');
  const [renameFolderDialog, setRenameFolderDialog] = useState({ open: false, oldName: '', newName: '' });
  const [uploadDialog, setUploadDialog] = useState({ open: false, folderName: '' });
  const [moveFileDialog, setMoveFileDialog] = useState({ open: false, fileName: '', targetFolder: '' });
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [folders, setFolders] = useState<string[]>([]);
  const [currentFolder, setCurrentFolder] = useState<string | null>(null);
  const [renameFileDialog, setRenameFileDialog] = useState<RenameFileDialog>({ 
    open: false, 
    oldName: '', 
    newName: '',
    folderPath: ''
  });
  const [errorNotification, setErrorNotification] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'name', order: 'asc' });
  const [createFolderDialog, setCreateFolderDialog] = useState({ open: false, folderName: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FileInfo[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>(['Home']);

  useEffect(() => {
    fetchFiles();
  }, [selectedCustomer, selectedJob, carbonClient]);

  const fetchFiles = async () => {
    if (!selectedCustomer || !selectedJob || !carbonClient) {
      setFiles([]);
      return;
    }

    setLoading(true);

    try {
      const sessionInfoString = localStorage.getItem('sessionInfo');
      if (!sessionInfoString) {
        throw new Error('No session info found');
      }
      const sessionInfo = JSON.parse(sessionInfoString);
      
      const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
      if (!customerInfo) {
        throw new Error('Selected customer not found in session info');
      }

      const storageKey = customerInfo.storageKey;

      const encodedJobName = encodeURIComponent(selectedJob.name);

      const response = await fetch(`${API_URL}/api/listFiles?customer=${encodeURIComponent(selectedCustomer)}&job=${encodedJobName}&storageKey=${encodeURIComponent(storageKey)}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to fetch files: ${errorData.error}. Details: ${errorData.details}`);
      }
      const fileList = await response.json();
      console.log('Received files:', fileList);
      
      const processedFileList = fileList.map((file: FileInfo) => ({
        ...file,
        lastModified: file.lastModified ? new Date(file.lastModified) : undefined
      }));
      
      setFiles(processedFileList);
      const folderSet = new Set<string>(
        fileList
          .filter((file: FileInfo) => file.isFolder)
          .map((file: FileInfo) => file.displayName)
          .filter((displayName: unknown): displayName is string => typeof displayName === 'string')
      );
      setFolders(Array.from(folderSet));
    } catch (error: unknown) {
      console.error('Error fetching files:', error);
      if (error instanceof Error) {
        console.error('Error details:', error.message);
      }
      setFiles([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (fileName: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfoString = localStorage.getItem('sessionInfo');
    if (!sessionInfoString) {
      console.error('No session info found');
      return;
    }
    const sessionInfo = JSON.parse(sessionInfoString);
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) {
      console.error('Selected customer not found in session info');
      return;
    }
    const storageKey = customerInfo.storageKey;

    const downloadUrl = `http://localhost:3001/api/downloadFile?customer=${selectedCustomer}&job=${selectedJob}&fileName=${fileName}&storageKey=${encodeURIComponent(storageKey)}`;
    
    const response = await fetch(downloadUrl);

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error downloading file');
    }
  };

  const handleCreateFolder = async () => {
    if (!selectedCustomer || !selectedJob || !createFolderDialog.folderName) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const response = await fetch('http://localhost:3001/api/createFolder', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob,
          folderName: createFolderDialog.folderName,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        console.log('Folder created successfully');
        setCreateFolderDialog({ open: false, folderName: '' });
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error creating folder:', errorData.error);
      }
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleRenameFolder = async () => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const renameResponse = await fetch('http://localhost:3001/api/renameFolder', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob,
          oldFolderName: renameFolderDialog.oldName,
          newFolderName: renameFolderDialog.newName,
          storageKey: customerInfo.storageKey
        }),
      });

      if (renameResponse.ok) {
        console.log('Folder renamed successfully');
        setRenameFolderDialog({ open: false, oldName: '', newName: '' });
        fetchFiles();
      } else {
        console.error('Error renaming folder');
      }
    } catch (error) {
      console.error('Error renaming folder:', error);
    }
  };

  const handleDeleteFolder = async (folderName: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const response = await fetch(
        `http://localhost:3001/api/deleteFolder?customer=${encodeURIComponent(selectedCustomer)}&job=${encodeURIComponent(selectedJob.name)}&folderName=${encodeURIComponent(folderName)}&storageKey=${encodeURIComponent(customerInfo.storageKey)}`, 
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        console.log('Folder deleted successfully');
        fetchFiles();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete folder');
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while deleting the folder');
      }
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('customer', selectedCustomer);
    formData.append('job', selectedJob?.name || '');
    formData.append('folderName', uploadDialog.folderName);
    formData.append('storageKey', customerInfo.storageKey);

    try {
      const response = await fetch('http://localhost:3001/api/uploadFile', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('File uploaded successfully');
        setUploadDialog({ open: false, folderName: '' });
        fetchFiles();
      } else {
        console.error('Error uploading file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleMoveFile = async () => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      console.log('Moving file:', {
        fileName: moveFileDialog.fileName,
        targetFolder: moveFileDialog.targetFolder,
        customer: selectedCustomer,
        jobName: selectedJob.name
      });

      const response = await fetch('http://localhost:3001/api/moveFile', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob.name, // Use job.name instead of the job object
          sourceFileName: moveFileDialog.fileName,
          targetFolderName: moveFileDialog.targetFolder,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        console.log('File moved successfully');
        setMoveFileDialog({ open: false, fileName: '', targetFolder: '' });
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error moving file:', errorData);
        throw new Error(errorData.error || 'Failed to move file');
      }
    } catch (error) {
      console.error('Error moving file:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while moving the file');
      }
    }
  };

  const handleContextMenu = (event: React.MouseEvent, item: string) => {
    event.preventDefault();
    setSelectedItem(item);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const handleDeleteFile = async (fileName: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const fullPath = fileName.startsWith('Docs/') ? fileName : `Docs/${fileName}`;
      console.log('Deleting file:', {
        customer: selectedCustomer,
        job: selectedJob.name,
        path: fullPath
      });

      const response = await fetch(`http://localhost:3001/api/deleteFile`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob.name,
          fileName: fullPath,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        console.log('File deleted successfully');
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error deleting file:', errorData);
        throw new Error(errorData.error || 'Failed to delete file');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while deleting the file');
      }
    }
  };

  const handleDelete = async (item: string) => {
    try {
      if (item.endsWith('/')) {
        // It's a folder
        await handleDeleteFolder(item.replace('Docs/', '').slice(0, -1));
      } else {
        // It's a file
        await handleDeleteFile(item.replace('Docs/', ''));
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred');
      }
    }
  };

  const sortFiles = (files: FileInfo[]): FileInfo[] => {
    const folders = files.filter(file => file.isFolder);
    const nonFolders = files.filter(file => !file.isFolder);

    const sortGroup = (group: FileInfo[]) => {
      return group.sort((a, b) => {
        if (sortConfig.field === 'name') {
          const comparison = a.displayName.localeCompare(b.displayName);
          return sortConfig.order === 'asc' ? comparison : -comparison;
        } else if (sortConfig.field === 'createdAt') {
          const dateA = a.lastModified instanceof Date ? a.lastModified.getTime() : 0;
          const dateB = b.lastModified instanceof Date ? b.lastModified.getTime() : 0;
          return sortConfig.order === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return 0;
      });
    };

    const sortedFolders = sortGroup(folders);
    const sortedNonFolders = sortGroup(nonFolders);

    return [...sortedFolders, ...sortedNonFolders];
  };

  const handleSort = (field: SortField) => {
    setSortConfig(prevConfig => ({
      field,
      order: prevConfig.field === field && prevConfig.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    if (term.trim() === '') {
      setSearchResults([]);
      return;
    }
    const results = files.filter(file => 
      file.displayName.toLowerCase().includes(term.toLowerCase())
    );
    setSearchResults(results);
  };

  const getCurrentFolderContents = () => {
    if (searchTerm.trim() !== '') {
      return sortFiles(searchResults);
    }
    const filteredFiles = files.filter((file: FileInfo) => 
      currentFolder === null 
        ? file.parentFolder === null 
        : file.parentFolder === currentFolder
    );
    return sortFiles(filteredFiles);
  };

  const handleFolderClick = (folderName: string) => {
    setCurrentFolder(folderName);
    setBreadcrumbs(prev => [...prev, folderName]);
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setCurrentFolder(newBreadcrumbs[newBreadcrumbs.length - 1] === 'Home' ? null : newBreadcrumbs[newBreadcrumbs.length - 1]);
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    setBreadcrumbs(newBreadcrumbs);
    setCurrentFolder(newBreadcrumbs[newBreadcrumbs.length - 1] === 'Home' ? null : newBreadcrumbs[newBreadcrumbs.length - 1]);
  };

  const handleItemClick = (event: React.MouseEvent, file: FileInfo) => {
    // Prevent folder opening when clicking on the ellipsis icon
    if ((event.target as HTMLElement).closest('.MuiIconButton-root')) {
      return;
    }
    if (file.isFolder) {
      if (searchTerm.trim() !== '') {
        // If we're in search mode, navigate to the folder and clear the search
        setCurrentFolder(file.parentFolder);
        setSearchTerm('');
        setSearchResults([]);
      } else {
        handleFolderClick(file.displayName);
      }
    }
  };

  const handleRenameFile = async () => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      // Use the folderPath to construct the correct paths
      const oldPath = renameFileDialog.oldName;
      const newPath = `${renameFileDialog.folderPath}/${renameFileDialog.newName}`;

      console.log('Renaming file:', { 
        oldPath, 
        newPath, 
        customerName: selectedCustomer, 
        jobName: selectedJob.name 
      });

      const response = await fetch('http://localhost:3001/api/renameFile', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob.name, // Use job.name instead of the job object
          oldFileName: oldPath,
          newFileName: newPath,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('File renamed successfully:', data.message);
        setRenameFileDialog({ open: false, oldName: '', newName: '', folderPath: '' });
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error renaming file. Response:', errorData);
        throw new Error(errorData.error || 'Failed to rename file');
      }
    } catch (error) {
      console.error('Error renaming file:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while renaming the file');
      }
    }
  };

  const handleOpenCreateFolderDialog = () => {
    setCreateFolderDialog({ open: true, folderName: '' });
  };

  const handleSelectFile = (fileName: string) => {
    setSelectedFiles(prev => {
      const newSet = new Set(prev);
      if (newSet.has(fileName)) {
        newSet.delete(fileName);
      } else {
        newSet.add(fileName);
      }
      return newSet;
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedFiles(new Set(getCurrentFolderContents().map(file => file.name)));
    } else {
      setSelectedFiles(new Set());
    }
  };

  const isAllSelected = getCurrentFolderContents().length > 0 && 
    selectedFiles.size === getCurrentFolderContents().length;

  const isIndeterminate = selectedFiles.size > 0 && selectedFiles.size < getCurrentFolderContents().length;

  // Helper to get first selected file
  const getFirstSelectedFile = (): string | undefined => {
    return Array.from(selectedFiles)[0];
  };

  const handleBulkDownload = async () => {
    if (!selectedCustomer || !selectedJob || selectedFiles.size === 0) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    // Download files sequentially using Array.from for iteration
    for (const item of Array.from(selectedFiles)) {
      try {
        const storageKey = customerInfo.storageKey;
        
        if (item.endsWith('/')) {
          // Handle folder download
          const downloadUrl = `http://localhost:3001/api/downloadFolder?customer=${selectedCustomer}&job=${selectedJob.name}&folderPath=${encodeURIComponent(item)}&storageKey=${encodeURIComponent(storageKey)}`;
          
          console.log('Downloading folder:', {
            folderPath: item,
            url: downloadUrl
          });

          const response = await fetch(downloadUrl);
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            const folderName = item.split('/').pop() || item;
            a.download = `${folderName}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } else {
            const errorData = await response.json();
            console.error('Error downloading folder:', errorData);
            setErrorNotification(`Failed to download folder: ${errorData.details || errorData.error}`);
          }
        } else {
          // Handle file download
          const cleanFileName = item.replace(/^Docs\//, '');
          const downloadUrl = `http://localhost:3001/api/downloadFile?customer=${selectedCustomer}&job=${selectedJob.name}&fileName=${encodeURIComponent(cleanFileName)}&storageKey=${encodeURIComponent(storageKey)}`;
          
          console.log('Downloading file:', {
            originalPath: item,
            cleanPath: cleanFileName,
            url: downloadUrl
          });

          const response = await fetch(downloadUrl);
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            const displayName = item.split('/').pop() || item;
            a.download = displayName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } else {
            const errorData = await response.json();
            console.error(`Error downloading file ${item}:`, errorData);
            setErrorNotification(`Failed to download ${item}: ${errorData.details || errorData.error}`);
          }
        }
      } catch (error) {
        console.error(`Error downloading ${item}:`, error);
        if (error instanceof Error) {
          setErrorNotification(`Failed to download ${item}: ${error.message}`);
        } else {
          setErrorNotification(`Failed to download ${item}`);
        }
      }
    }
  };

  const handleDownloadFolder = async (folderPath: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const storageKey = customerInfo.storageKey;
      const downloadUrl = `http://localhost:3001/api/downloadFolder?customer=${selectedCustomer}&job=${selectedJob.name}&folderPath=${encodeURIComponent(folderPath)}&storageKey=${encodeURIComponent(storageKey)}`;
      
      console.log('Downloading folder:', {
        folderPath,
        url: downloadUrl
      });

      const response = await fetch(downloadUrl);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const folderName = folderPath.split('/').pop() || folderPath;
        a.download = `${folderName}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const errorData = await response.json();
        console.error('Error downloading folder:', errorData);
        setErrorNotification(`Failed to download folder: ${errorData.details || errorData.error}`);
      }
    } catch (error) {
      console.error('Error downloading folder:', error);
      if (error instanceof Error) {
        setErrorNotification(`Failed to download folder: ${error.message}`);
      } else {
        setErrorNotification('Failed to download folder');
      }
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          DocStore
        </Typography>
        {selectedCustomer && selectedJob && (
          <>
            <Toolbar sx={{ gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<CloudUpload />}
                onClick={() => setUploadDialog({ open: true, folderName: currentFolder || '' })}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                startIcon={<CreateNewFolder />}
                onClick={() => setCreateFolderDialog({ open: true, folderName: '' })}
              >
                New Folder
              </Button>
              <Button
                variant="contained"
                startIcon={<GetApp />}
                onClick={handleBulkDownload}
                disabled={selectedFiles.size === 0}
                sx={{ mr: 1 }}
              >
                Download {selectedFiles.size > 0 ? `(${selectedFiles.size})` : ''}
              </Button>
              <Button
                variant="contained"
                startIcon={<FileCopy />}
                onClick={() => {
                  const firstFile = getFirstSelectedFile();
                  if (firstFile) {
                    setMoveFileDialog({ open: true, fileName: firstFile, targetFolder: '' });
                  }
                }}
                disabled={selectedFiles.size !== 1}
                sx={{ mr: 1 }}
              >
                Move
              </Button>
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => {
                  const firstFile = getFirstSelectedFile();
                  if (firstFile) {
                    const displayName = firstFile.split('/').pop() || '';
                    const folderPath = firstFile.substring(0, firstFile.lastIndexOf('/'));
                    setRenameFileDialog({
                      open: true,
                      oldName: firstFile,
                      newName: displayName,
                      folderPath
                    });
                  }
                }}
                disabled={selectedFiles.size !== 1}
                sx={{ mr: 1 }}
              >
                Rename
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                onClick={() => {
                  const firstFile = getFirstSelectedFile();
                  if (firstFile) {
                    handleDelete(firstFile);
                  }
                }}
                disabled={selectedFiles.size !== 1}
              >
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                size="small"
                placeholder="Search files..."
                value={searchTerm}
                onChange={(e) => {
                  const term = e.target.value;
                  setSearchTerm(term);
                  if (term.trim() === '') {
                    setSearchResults([]);
                  } else {
                    const results = files.filter(file => 
                      file.displayName.toLowerCase().includes(term.toLowerCase()) ||
                      file.name.toLowerCase().includes(term.toLowerCase())
                    );
                    setSearchResults(results);
                  }
                }}
                InputProps={{
                  startAdornment: <Search />
                }}
              />
            </Toolbar>

            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box sx={{ padding: 2, paddingLeft: 0 }}>
                  <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    {breadcrumbs.map((crumb, index) => (
                      <Typography
                        key={index}
                        color={index === breadcrumbs.length - 1 ? 'text.primary' : 'inherit'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleBreadcrumbClick(index)}
                      >
                        {crumb}
                      </Typography>
                    ))}
                  </Breadcrumbs>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" sx={{ width: '48px' }}>
                          <Checkbox
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            indeterminate={isIndeterminate}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '40%' }}>
                          <Button
                            onClick={() => handleSort('name')}
                            startIcon={<Sort />}
                            color={sortConfig.field === 'name' ? 'secondary' : 'inherit'}
                          >
                            Name {getSortArrow('name', sortConfig)}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: '80px' }}>Actions</TableCell>
                        <TableCell sx={{ width: '30%' }}>
                          <Button
                            onClick={() => handleSort('createdAt')}
                            startIcon={<Sort />}
                            color={sortConfig.field === 'createdAt' ? 'secondary' : 'inherit'}
                          >
                            Modified {getSortArrow('createdAt', sortConfig)}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>Size</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getCurrentFolderContents().map((file: FileInfo) => (
                        <TableRow
                          key={file.name}
                          hover
                          onClick={(event) => handleItemClick(event, file)}
                          style={{ cursor: file.isFolder ? 'pointer' : 'default' }}
                        >
                          <TableCell padding="checkbox" sx={{ width: '48px' }}>
                            <Checkbox
                              className="MuiCheckbox-root"
                              checked={selectedFiles.has(file.name)}
                              onChange={(e) => handleSelectFile(file.name)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell sx={{ width: '40%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {file.isFolder ? 
                                <i className="bi bi-folder-fill text-primary" style={{ fontSize: '1.2rem', marginRight: '8px' }} /> : 
                                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', height: 24 }}>
                                  {getFileIcon(file.displayName)}
                                </Box>
                              }
                              {searchTerm.trim() !== '' ? file.name : file.displayName}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ width: '80px' }}>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleContextMenu(event, file.name);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>{file.lastModified?.toLocaleString() ?? 'Unknown'}</TableCell>
                          <TableCell sx={{ width: '100px' }}>{file.isFolder ? '--' : formatFileSize(file.size)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
        {!selectedCustomer || !selectedJob && (
          <Typography>Please select a customer and job to view files and folders.</Typography>
        )}
      </Paper>

      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {!selectedItem?.endsWith('/') && (
          <MenuItem onClick={() => {
            handleContextMenuClose();
            handleDownload(selectedItem!.replace('Docs/', ''));
          }}>Download</MenuItem>
        )}
        <MenuItem onClick={() => {
          handleContextMenuClose();
          setMoveFileDialog({ open: true, fileName: selectedItem!, targetFolder: '' });
        }}>Move</MenuItem>
        {selectedItem?.endsWith('/') ? (
          <MenuItem onClick={() => {
            handleContextMenuClose();
            setRenameFolderDialog({ open: true, oldName: selectedItem!.replace('Docs/', '').slice(0, -1), newName: '' });
          }}>Rename</MenuItem>
        ) : (
          <MenuItem onClick={() => {
            handleContextMenuClose();
            const fileName = selectedItem!.split('/').pop() || '';
            const folderPath = selectedItem!.substring(0, selectedItem!.lastIndexOf('/'));
            setRenameFileDialog({ 
              open: true, 
              oldName: selectedItem!, 
              newName: fileName,
              folderPath: folderPath
            });
          }}>Rename</MenuItem>
        )}
        <MenuItem onClick={() => {
          handleContextMenuClose();
          handleDelete(selectedItem!);
        }}>Delete</MenuItem>
        {selectedItem?.endsWith('/') && (
          <MenuItem onClick={() => {
            handleDownloadFolder(selectedItem);
            handleContextMenuClose();
          }}>
            <ListItemIcon>
              <GetApp fontSize="small" />
            </ListItemIcon>
            Download as ZIP
          </MenuItem>
        )}
      </Menu>

      <Dialog open={renameFolderDialog.open} onClose={() => setRenameFolderDialog({ open: false, oldName: '', newName: '' })}>
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            fullWidth
            value={renameFolderDialog.newName}
            onChange={(e) => setRenameFolderDialog({ ...renameFolderDialog, newName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFolderDialog({ open: false, oldName: '', newName: '' })}>Cancel</Button>
          <Button onClick={handleRenameFolder}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={uploadDialog.open} onClose={() => setUploadDialog({ open: false, folderName: '' })}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="upload-folder-label">Target Folder</InputLabel>
            <Select
              labelId="upload-folder-label"
              value={uploadDialog.folderName}
              onChange={(e) => setUploadDialog({ ...uploadDialog, folderName: e.target.value as string })}
              label="Target Folder"
            >
              <MenuItem value="">
                <em>Root</em>
              </MenuItem>
              {folders.map((folder) => (
                <MenuItem key={folder} value={folder}>{folder}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialog({ open: false, folderName: '' })}>Cancel</Button>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUpload />}
          >
            Upload File
            <input
              type="file"
              hidden
              onChange={handleUpload}
            />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={moveFileDialog.open} onClose={() => setMoveFileDialog({ open: false, fileName: '', targetFolder: '' })}>
        <DialogTitle>Move File</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Moving: {moveFileDialog.fileName.replace('Docs/', '')}
          </Typography>
          <FormControl fullWidth margin="dense">
            <InputLabel id="target-folder-label">Target Folder</InputLabel>
            <Select
              labelId="target-folder-label"
              value={moveFileDialog.targetFolder}
              onChange={(e) => setMoveFileDialog({ ...moveFileDialog, targetFolder: e.target.value as string })}
              label="Target Folder"
            >
              <MenuItem value="">
                <em>Root</em>
              </MenuItem>
              {folders.map((folder) => (
                <MenuItem key={folder} value={folder}>{folder}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveFileDialog({ open: false, fileName: '', targetFolder: '' })}>Cancel</Button>
          <Button onClick={handleMoveFile}>Move</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={renameFileDialog.open} onClose={() => setRenameFileDialog({ open: false, oldName: '', newName: '', folderPath: '' })}>
        <DialogTitle>Rename File</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New File Name"
            fullWidth
            value={renameFileDialog.newName}
            onChange={(e) => setRenameFileDialog({ ...renameFileDialog, newName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFileDialog({ open: false, oldName: '', newName: '', folderPath: '' })}>Cancel</Button>
          <Button onClick={handleRenameFile}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={createFolderDialog.open} onClose={() => setCreateFolderDialog({ open: false, folderName: '' })}>
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={createFolderDialog.folderName}
            onChange={(e) => setCreateFolderDialog({ ...createFolderDialog, folderName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setCreateFolderDialog({ open: false, folderName: '' })}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleCreateFolder} disabled={!createFolderDialog.folderName}>Create</Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={errorNotification !== null} 
        autoHideDuration={6000} 
        onClose={() => setErrorNotification(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorNotification(null)} severity="error" sx={{ width: '100%' }}>
          {errorNotification}
        </Alert>
      </Snackbar>
    </Box>
  );
}; 