import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { JobInfo, CustomerInfo } from '../types/types';

const Header: React.FC = () => {
  const { isAuthenticated, carbonClient, logout } = useAuth();
  const { selectedCustomer, selectedJob, setSelectedCustomer, setSelectedJob } = useJob();
  const navigate = useNavigate();

  const [customers, setCustomers] = useState<CustomerInfo[]>([]);
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient) {
        setIsLoading(true);
        try {
          const customerList = await carbonClient.getCustomers();
          console.log('Fetched customers:', customerList);
          setCustomers(customerList);
        } catch (error) {
          console.error('Error fetching customers:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.log('Carbon client not available');
      }
    };
    fetchCustomers();
  }, [carbonClient]);

  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && selectedCustomer) {
        setIsLoading(true);
        try {
          const jobList = await carbonClient.getJobs(selectedCustomer);
          console.log('Fetched jobs:', jobList);
          setJobs(jobList);
        } catch (error) {
          console.error('Error fetching jobs:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchJobs();
  }, [carbonClient, selectedCustomer]);

  const closeCurrentJob = useCallback(async () => {
    if (carbonClient && selectedJob) {
      try {
        await carbonClient.closeJob();
        console.log('Closed job:', selectedJob.id);
        setSelectedJob(null);
      } catch (error) {
        console.error('Error closing job:', error);
      }
    }
  }, [carbonClient, selectedJob, setSelectedJob]);

  const handleCustomerChange = (event: SelectChangeEvent<string>) => {
    setSelectedCustomer(event.target.value);
    setSelectedJob(null);
  };

  const handleJobChange = async (event: SelectChangeEvent<string>) => {
    await closeCurrentJob();
    
    const jobId = event.target.value;
    const selectedJobObject = jobs.find(job => job.id === jobId);
    if (selectedJobObject) {
      setSelectedJob(selectedJobObject);
    }
  };

  const handleLogout = async () => {
    await closeCurrentJob();
    await logout();
    navigate('/');
  };

  console.log('Rendering Header. isAuthenticated:', isAuthenticated);

  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between', 
          backgroundColor: 'white',
          borderBottom: '6px solid #FFC000',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src="/assets/img/bayes-price-logo.svg" 
            alt="Bayes Price Logo" 
            style={{ height: '30px', marginRight: '16px' }} 
          />
        </Box>
        {isAuthenticated && (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Customer</InputLabel>
              <Select
                value={selectedCustomer || ''}
                label="Customer"
                onChange={handleCustomerChange}
                disabled={isLoading}
              >
                {customers.map((customer) => (
                  <MenuItem key={customer.name} value={customer.name}>
                    {customer.displayName || customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Job</InputLabel>
              <Select
                value={selectedJob?.id || ''}
                label="Job"
                onChange={handleJobChange}
                disabled={!selectedCustomer || isLoading}
              >
                {jobs.map((job) => (
                  <MenuItem key={job.id} value={job.id}>{job.displayName || job.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button color="inherit" component={RouterLink} to="/dashboard" sx={{ color: 'text.primary' }} disabled={isLoading}>
              Dashboard
            </Button>
            <Button color="inherit" component={RouterLink} to="/tables" sx={{ color: 'text.primary' }} disabled={isLoading}>
              Tables
            </Button>
            <Button color="inherit" component={RouterLink} to="/specification" sx={{ color: 'text.primary' }} disabled={isLoading}>
              Specification
            </Button>
            <Button color="inherit" component={RouterLink} to="/copilot" sx={{ color: 'text.primary' }} disabled={isLoading}>
              CoPilot
            </Button>
            <Button color="inherit" component={RouterLink} to="/files" sx={{ color: 'text.primary' }} disabled={isLoading}>
              Files
            </Button>
            <Button color="inherit" component={RouterLink} to="/experiments" sx={{ color: 'text.primary' }} disabled={isLoading}>
              Experiments
            </Button>
            <Button color="inherit" onClick={handleLogout} sx={{ color: 'text.primary' }} disabled={isLoading}>
              Logout
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header; 