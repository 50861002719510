import axios from 'axios';
import { ResetPasswordRequest, GenericResponse } from '../types/types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

export class LicensingClient {
  private axiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async resetPassword(request: ResetPasswordRequest): Promise<GenericResponse> {
    try {
      console.log('Resetting password');
      const response = await this.axiosInstance.post<GenericResponse>('api/reset-password', request);
      console.log('Reset password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          throw new Error(`Failed to reset password: ${error.response.data.message || error.response.statusText}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          throw new Error('Failed to reset password: No response received from server');
        } else {
          console.error('Error setting up request:', error.message);
          throw new Error(`Failed to reset password: ${error.message}`);
        }
      }
      throw new Error(`Failed to reset password: ${(error as Error).message}`);
    }
  }
}

export const licensingClient = new LicensingClient(); 