import React, { useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { Box, Paper, Typography, TextField, Button, Grid, IconButton, Tooltip, Tabs, Tab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stepper, Step, StepLabel, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Alert, Select, MenuItem, FormControlLabel, Checkbox, LinearProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Components } from 'react-markdown';
import { DataGridPro, GridColDef, GridRowsProp, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { GenNode, ImportSettings } from '../types/types';
import { Folder, Description, ExpandMore, FiberManualRecord as DotIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled, alpha } from '@mui/material/styles';
import { DragEvent } from 'react';
import { Button as MuiButton } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';

// Update the ExtendedGenNode type
interface ExtendedGenNode extends Omit<GenNode, 'children' | 'id' | 'parentId'> {
  id: string;
  parentId: string | null;
  children: ExtendedGenNode[] | null;
  label: string;
}

const API_URL = process.env.REACT_APP_API_URL

// Add this type definition at the top of your file, after the imports
type ChatMessage = {
  role: 'user' | 'assistant';
  content: string;
};

// Add this component after your imports and before the CoPilot component
const MarkdownRenderer: React.FC<{ 
  content: string;
  setThemes: React.Dispatch<React.SetStateAction<Array<{ id: number; text: string }>>>;
  setChatTab: React.Dispatch<React.SetStateAction<'chat' | 'themeTagger'>>;
}> = ({ content, setThemes, setChatTab }) => {
  const [hasOrderedList, setHasOrderedList] = useState(false);

  useEffect(() => {
    // Check for numbered list with bold items
    const containsOrderedList = /^\d+\.\s\*\*.+\*\*:/m.test(content);
    setHasOrderedList(containsOrderedList);
  }, [content]);

  const extractThemes = () => {
    const extractedThemes = content.split('\n')
      .filter(line => /^\d+\.\s\*\*.+\*\*:/.test(line))
      .map((line, index) => {
        const match = line.match(/^\d+\.\s\*\*(.+?)\*\*:/);
        return {
          id: Date.now() + index,
          text: match ? match[1].trim() : ''
        };
      })
      .filter(theme => theme.text !== '');

    const notInterestedTheme = {
      id: Date.now() + extractedThemes.length,
      text: "Not interested / Answer not clear"
    };
    
    const finalThemes = [...extractedThemes, notInterestedTheme];
    
    setThemes(finalThemes);
    setChatTab('themeTagger');
  };

  return (
    <>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      {hasOrderedList && (
        <Button
          variant="contained"
          size="small"
          onClick={extractThemes}
          sx={{ mt: 1, width: '100%' }}
        >
          Move to Theme Tagger
        </Button>
      )}
    </>
  );
};

// Update the interface for middleware data
interface MiddlewareData {
  exists: boolean;
  hasData: boolean;
  recordCount: number;
  totalInterviewCount: number;
  isComplete: boolean;
  uniqueInterviewCount: number;
  variableCount: number;
  variableIds: string[];
}

// Add this component for handling progress updates
const ClassificationProgress = React.memo(({ data }: { data: MiddlewareData }) => {
  const percentage = data.uniqueInterviewCount > 0 
    ? Math.min((data.recordCount / data.uniqueInterviewCount) * 100, 100)
    : 0;
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="body2" component="div">
        Records processed: <span style={{ display: 'inline-block', minWidth: '60px' }}>{data.recordCount || 0}</span> / {data.uniqueInterviewCount || 0}
      </Typography>
      <Typography variant="body2">
        Total interviews: {data.totalInterviewCount || 0}
      </Typography>
      <LinearProgress 
        variant="determinate" 
        value={percentage}
        sx={{ height: 8, borderRadius: 1 }}
      />
    </Box>
  );
});

export const CoPilot: React.FC = () => {
  const { carbonClient, isAuthenticated, isClientReady } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [variableTree, setVariableTree] = useState<ExtendedGenNode[]>([]);
  const [expandedVariableItems, setExpandedVariableItems] = useState<string[]>([]);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [variablesField, setVariablesField] = useState('');
  const [filterField, setFilterField] = useState('');
  const [selectedNode, setSelectedNode] = useState<ExtendedGenNode | null>(null);
  const [activeTab, setActiveTab] = useState<'classification' | 'chat'>('classification');
  const [chatInput, setChatInput] = useState('');
  const [classifierManagementVisible, setClassifierManagementVisible] = useState(false);
  const [chatTab, setChatTab] = useState<'chat' | 'themeTagger'>('chat');
  const [activeStep, setActiveStep] = useState(0);
  const [themes, setThemes] = useState<Array<{ id: number; text: string }>>([]);
  const [editingTheme, setEditingTheme] = useState<{ id: number; text: string } | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [primaryVariable, setPrimaryVariable] = useState<string | null>(null);
  const [chatThemes, setChatThemes] = useState<string[]>([]);
  const [showTagButton, setShowTagButton] = useState(false);
  const [taggingProgress, setTaggingProgress] = useState(0);
  const [isTagging, setIsTagging] = useState(false);
  const [taggingStatus, setTaggingStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [tagRunCount, setTagRunCount] = useState(0);
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [previewedGridColumns, setPreviewedGridColumns] = useState<GridColDef[]>([]);
  const [previewedGridRows, setPreviewedGridRows] = useState<GridRowsProp>([]);
  const [allVariables, setAllVariables] = useState<string[]>([]);
  const [newVariableName, setNewVariableName] = useState('');
  const [newSentimentVariableName, setNewSentimentVariableName] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataError, setDataError] = useState<string | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hasVariables, setHasVariables] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [chatError, setChatError] = useState<string | null>(null);
  const [includeSentimentAnalysis, setIncludeSentimentAnalysis] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [classificationComplete, setClassificationComplete] = useState(false);
  const [importComplete, setImportComplete] = useState(false);
  const [middlewareData, setMiddlewareData] = useState<MiddlewareData[]>([]);
  const [isLoadingMiddlewareData, setIsLoadingMiddlewareData] = useState(false);
  const [middlewareDataError, setMiddlewareDataError] = useState<string | null>(null);
  const [canAccessThirdStep, setCanAccessThirdStep] = useState(true);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);

  // Add this near the top of the component after the useJob hook
  useEffect(() => {
    // Log the initial state
    console.log('TextAnalysis mounted with job:', {
      selectedJob,
      selectedCustomer,
      sessionInfo: JSON.parse(localStorage.getItem('sessionInfo') || '{}')
    });
  }, []); // Run once on mount

  // Update the existing job context effect with more details
  useEffect(() => {
    console.log('Job context changed:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJob?.id,
      sessionInfo: localStorage.getItem('sessionInfo') ? 'present' : 'missing'
    });
  }, [selectedCustomer, selectedJob]);

  // Add effect to log job context changes
  useEffect(() => {
    console.log('Job context updated:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJob?.id
    });
  }, [selectedCustomer, selectedJob]);

  useEffect(() => {
    const openJobAndFetchVariables = async () => {
      if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
        try {
          setIsLoading(true);
          await carbonClient.openCloudJob(selectedCustomer, selectedJob);
          const specAggregate = await carbonClient.getSpecAggregate();
          if (Array.isArray(specAggregate.variableTree)) {
            setVariableTree(specAggregate.variableTree.map(mapToExtendedGenNode));
          }
        } catch (error) {
          console.error('Error opening job or fetching variables:', error);
          setErrorMessage(`Error: ${(error as Error).message}`);
        } finally {
          setIsLoading(false);
        }
      }
    };
    openJobAndFetchVariables();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const fetchDataFromAPI = async () => {
    setIsDataLoading(true);
    setDataError(null);
    
    try {
      if (!carbonClient || !isClientReady) {
        throw new Error('Carbon client is not ready');
      }

      if (!selectedCustomer) {
        throw new Error('No customer selected');
      }
      
      if (!selectedJob) {
        throw new Error('No job selected');
      }

      if (!variablesField.trim()) {
        throw new Error('Please specify at least one variable');
      }

      const varnames = variablesField.split(',').map(v => v.trim()).filter(v => v).join(',');
      const filter = filterField.trim();
      
      console.log('Fetching data with params:', {
        job_id: selectedJob?.id,
        varnames,
        filter,
        primary_variable: primaryVariable
      });

      const response = await axios({
        method: 'GET',
        url: `${API_URL}/api/run-join`,
        params: {
          job_id: selectedJob?.id,
          varnames,
          filter,
          primary_variable: primaryVariable
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Accept": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        }
      });

      console.log('API Response:', response);

      if (!response.data) {
        throw new Error('No data received from API');
      }

      loadDataIntoTable(response.data);
      setIsDataLoaded(true);
      setAllVariables(variablesField.split(',').map(v => v.trim()).filter(v => v));
      
      if (!primaryVariable && variablesField.split(',').map(v => v.trim()).filter(v => v).length > 0) {
        setPrimaryVariable(variablesField.split(',').map(v => v.trim()).filter(v => v)[0]);
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
      setDataError(error instanceof Error ? error.message : 'An unknown error occurred');
      setIsDataLoaded(false);
      clearGridData();
    } finally {
      setIsDataLoading(false);
    }
  };

  const mapToExtendedGenNode = (node: GenNode): ExtendedGenNode => ({
    ...node,
    id: node.id.toString(),
    parentId: node.parentId?.toString() || null,
    children: node.children ? node.children.map(mapToExtendedGenNode) : null,
    label: node.name || node.value1 || node.value2 || 'Unnamed Node'
  });

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedVariableItems(nodeIds);
  }, []);

  const handleVariableClick = async (node: ExtendedGenNode) => {
    setSelectedNode(node);
    if (node.type === 'Variable' || node.type === 'Codeframe') {
      await handleVariableExpand(node.id);
    }
  };

  const handleVariableExpand = async (variableId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, variableId);
      if (node) {
        console.log(`Fetching variables for node:`, node);
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log(`Using node name/id: ${nodeName}`);
        
        if (!node.children || node.children.length === 0) {
          const variables = await carbonClient.getVarNodes(nodeName);
          console.log(`Fetched codes for ${nodeName}:`, variables);
          if (variables.length === 0) {
            console.log(`No codes found for ${nodeName} (ID: ${variableId})`);
            setErrorMessage(`No codes found for ${nodeName}`);
          } else if (variables[0].type === 'Codeframe' && variables[0].children) {
            updateTreeWithCodeframe(variableId, variables);
          } else {
            updateTreeWithChildVariables(variableId, variables);
          }
        }
        
        setExpandedVariableItems(prev => {
          if (prev.includes(variableId)) {
            return prev.filter(id => id !== variableId);
          } else {
            return [...prev, variableId];
          }
        });

        setErrorMessage(null);
      } else {
        console.error(`Variable not found for ID: ${variableId}`);
        setErrorMessage(`Variable not found for ID: ${variableId}`);
      }
    } catch (error) {
      console.error('Error fetching node variables:', error);
      setErrorMessage(`Error fetching node variables: ${error instanceof Error ? error.message : String(error)}`);
    }
  };

  const updateTreeWithChildVariables = (parentId: string, childVariables: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === parentId) {
            return {
              ...variable,
              children: childVariables.map(mapToExtendedGenNode),
              isExpanded: true
            }
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) }
          }
          return variable
        })
      }
      return updateVariables(prevTree);
    })
  }

  const updateTreeWithCodeframe = (variableId: string, codeframe: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === variableId) {
            const codeNodes: ExtendedGenNode[] = codeframe[0].children?.map(code => ({
              ...code,
              id: `${variableId}_${code.id}`,
              type: 'Code',
              name: `${code.value1 || ''} - ${code.value2 || ''}`,
              isExpanded: false,
              children: null, // Change this from undefined to null
              label: `${code.value1 || ''} - ${code.value2 || ''}`, // Add this line
              parentId: variableId, // Add this line
            })) || [];

            return { ...variable, children: codeNodes, isExpanded: true };
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) };
          }
          return variable;
        });
      };
      return updateVariables(prevTree);
    });
  };

  const handleSelectedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    console.log('RichTreeView selection changed:', nodeIds);
    setSelectedVariables(nodeIds);
    // Remove the call to clearGridData()
  }, []);

  const findVariable = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id.toString() === id) {
        return node;
      }
      if (node.children) {
        const found = findVariable(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // We can keep the clearGridData function in case we need it elsewhere
  const clearGridData = () => {
    setGridColumns([]);
    setGridRows([]);
  };

  const getVariableIcon = (variable: ExtendedGenNode | null) => {
    if (!variable) return <DotIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;

    if (variable.isFolder) return <Folder fontSize="medium" sx={{ mr: 1, color: 'lightblue' }} />;
    if (variable.type === 'Variable' || variable.type === 'Axis') {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex', mr: 1 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              background: 'radial-gradient(circle at 30% 30%, #FF6B6B, #FF0000)',
            }}
          />
          <ExpandMore sx={{ 
            position: 'absolute', 
            color: '#FFFFFF', 
            fontSize: 20, 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)'
          }} />
        </Box>
      );
    }
    if (variable.type === 'Code') {
      return (
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: '50%',
            mr: 1,
            background: 'radial-gradient(circle at 30% 30%, #FFFF66, #CCCC00)',
          }}
        />
      );
    }
    return <DotIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;
  };

  // Add this helper function to find a node by its ID
  const findNodeById = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // Add this helper function to find the parent variable of a code
  const findParentVariable = (nodes: ExtendedGenNode[], codeId: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.children) {
        const codeNode = node.children.find(child => child.id === codeId);
        if (codeNode) return node;
        const found = findParentVariable(node.children, codeId);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>, field: 'variables' | 'filter') => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    try {
      const node: ExtendedGenNode = JSON.parse(data);
      let value = (node.name || node.value1 || node.value2 || '').trim();

      if (field === 'variables' && node.type === 'Code') {
        const parentVariable = findParentVariable(variableTree, node.id);
        if (parentVariable) {
          value = (parentVariable.name || parentVariable.value1 || parentVariable.value2 || '').trim();
        }
      }

      if (value) {
        if (field === 'variables') {
          setVariablesField(prev => {
            const prevTrimmed = prev.trim();
            const newValue = prevTrimmed ? `${prevTrimmed},${value}` : value;
            const updatedVariables = newValue.split(',').map(v => v.trim()).filter(v => v);
            setAllVariables(updatedVariables);
            setHasVariables(updatedVariables.length > 0);
            
            if (!primaryVariable) {
              setPrimaryVariable(updatedVariables[0]);
            }
            
            return newValue;
          });
        } else {
          setFilterField(prev => {
            const prevTrimmed = prev.trim();
            return prevTrimmed ? `${prevTrimmed},${value}(*)` : `${value}(*)`;
          });
        }
      }
    } catch (error) {
      console.error('Error parsing dropped data:', error);
    }
  };

  // Modify the existing useEffect to not fetch data on mount
  useEffect(() => {
    // We'll fetch data when the user clicks the refresh button or changes variables/filters
  }, []);

  // Update these handlers to not trigger data fetching
  const handleVariablesFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setVariablesField(newValue);
    const variables = newValue.split(',').map(v => v.trim()).filter(v => v);
    setAllVariables(variables);
    setHasVariables(variables.length > 0);
    
    // Update primary variable if needed
    setPrimaryVariable(prev => {
      if (!prev && variables.length > 0) {
        return variables[0];
      }
      if (prev && !variables.includes(prev)) {
        return variables[0] || null;
      }
      return prev;
    });
  };

  const handleFilterFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterField(e.target.value);
    // Remove the call to debouncedFetchData()
  };

  // Add this new function to handle manual refresh
  const handleRefreshData = () => {
    fetchDataFromAPI();
  };

  // Add this useEffect to log state changes
  useEffect(() => {
    console.log('allVariables updated:', allVariables);
    console.log('primaryVariable updated:', primaryVariable);
  }, [allVariables, primaryVariable]);

  const handleDragStart = (event: DragEvent<HTMLLIElement>, node: ExtendedGenNode) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(node));
    event.dataTransfer.effectAllowed = 'copy';
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const item = findVariable(variableTree, itemId);

    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box 
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            onClick={() => item && handleVariableClick(item)}
            draggable
            onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
              if (item) {
                handleDragStart(event as unknown as DragEvent<HTMLLIElement>, item);
              }
            }}
          >
            {getVariableIcon(item)}
            <Typography variant="body2" sx={{ mr: 1 }}>{label}</Typography>
            {item?.type === 'Code' && item.meta && item.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        }
      />
    );
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: 'classification' | 'chat') => {
    setActiveTab(newValue);
  };

  const handleChatSubmit = async () => {
    if (!chatInput.trim() || !isDataLoaded) return;

    setIsChatLoading(true);
    setChatError(null);

    // Add user message to UI chat history
    const userMessage: ChatMessage = {
      role: 'user',
      content: chatInput
    };
    setChatHistory(prev => [...prev, userMessage]);

    // Extract question and format responses
    const questionKey = Object.keys(gridRows[0]).find(key => key !== 'id');
    const question = questionKey || '';
    const formattedData = gridRows.map((row, index) => ({
      id: index + 1,
      response: row[questionKey || '']
    }));

    // Construct fresh message array for each request - no chat history needed
    const messages = [
      {
        role: 'system',
        content: 'You are an expert at analyzing text data and identifying common themes. When asked about themes:\n\n1. ONLY derive themes from patterns in the actual data provided\n2. Each theme must be directly supported by multiple examples in the data\n3. Use clear, concise theme names that reflect the actual content\n4. Format themes as a numbered list with bold titles and descriptions, e.g.:\n   1. **Specific Issue**: Description with examples from the data\n   2. **Another Issue**: Description with examples from the data\n\nFor non-theme questions, answer concisely based on the context.'
      },
      {
        role: 'user',
        content: `Question being analyzed: "${question}"\n\nResponses to analyze (${formattedData.length} records):\n\n${formattedData.map(item => `${item.id}. "${item.response}"`).join('\n\n')}`
      },
      userMessage // Add just the current user query
    ];

    console.log('Sending messages to API:', messages);

    try {
      const response = await axios({
        method: 'post',
        url: `${API_URL}/api/v1/chat/completions`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        },
        data: {
          model: "llama3.1:8b-instruct-q8_0",
          stream: false,
          max_tokens: 6048,
          messages: messages
        },
      });

      console.log('API response:', response.data);

      let assistantMessage: ChatMessage;
      if (response.data.choices && response.data.choices[0] && response.data.choices[0].message) {
        assistantMessage = { 
          role: 'assistant', 
          content: response.data.choices[0].message.content 
        };
      } else if (typeof response.data === 'string') {
        assistantMessage = { 
          role: 'assistant', 
          content: response.data 
        };
      } else {
        throw new Error('Unexpected response structure from API');
      }

      // Add assistant response to UI chat history
      setChatHistory(prev => [...prev, assistantMessage]);
      setChatInput('');
    } catch (error) {
      console.error('Chat error:', error);
      let errorMessage = 'Failed to get response from AI';
      if (error instanceof Error) {
        errorMessage = `Error: ${error.message}`;
      }
      setChatError(errorMessage);
      // Add error message to UI chat history
      setChatHistory(prev => [...prev, { role: 'assistant', content: `Error: ${errorMessage}` }]);
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleTagThemes = () => {
    setThemes(chatThemes.map((text, id) => ({ id, text })));
    setChatTab('themeTagger');
    setShowTagButton(false);
  };

  const handleRunClassifier = (id: string) => {
    console.log(`Running classifier with ID: ${id}`);
    // Implement the logic to run the classifier
  };

  const handleChatTabChange = (event: React.SyntheticEvent, newValue: 'chat' | 'themeTagger') => {
    setChatTab(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 1 && !classificationComplete) {
        // If classification is not complete, skip to the third step
        fetchMiddlewareData();
        startPollingMiddlewareData(); // Start polling when manually moving to step 3
        return 2;
      }
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 2 && !classificationComplete) {
        // If coming back from the third step and classification is not complete, go back to the first step
        return 0;
      }
      return prevActiveStep - 1;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setPreviewGenerated(false);
    setPreviewedGridColumns([]);
    setPreviewedGridRows([]);
    setClassificationComplete(false);
    setImportComplete(false);
  };

  // Updated CustomStepIcon component
  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box
          sx={{
            width: 32,
            height: 32,
            borderRadius: '50%',
            backgroundColor: active ? 'primary.main' : 'grey.300',
            color: active ? 'white' : 'grey.700', // Changed from 'text.primary' to 'grey.700'
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          {icon}
        </Box>
      </Box>
    );
  };

  // Updated styled components for the tabs
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: '40px',
    height: '40px',
    '& .MuiTabs-flexContainer': {
      height: '100%',
      display: 'flex',
    },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    minHeight: '40px',
    height: '40px',
    flex: 1, // This will make each tab take up equal space
    maxWidth: 'none', // Remove max-width constraint
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    padding: '0 8px', // Reduced padding to accommodate longer text if needed
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  }));

  const handleEditTheme = (theme: { id: number; text: string }) => {
    setEditingTheme(theme);
    setIsEditDialogOpen(true);
  };

  const handleDeleteTheme = (id: number) => {
    setThemes(themes.filter(theme => theme.id !== id));
  };

  const handleAddTheme = () => {
    const newId = Math.max(...themes.map(t => t.id), 0) + 1;
    setEditingTheme({ id: newId, text: "" });
    setIsEditDialogOpen(true);
  };

  const handleSaveTheme = () => {
    if (editingTheme) {
      setThemes(prevThemes => {
        const existingThemeIndex = prevThemes.findIndex(t => t.id === editingTheme.id);
        if (existingThemeIndex !== -1) {
          // Update existing theme
          const updatedThemes = [...prevThemes];
          updatedThemes[existingThemeIndex] = editingTheme;
          return updatedThemes;
        } else {
          // Add new theme
          return [...prevThemes, editingTheme];
        }
      });
    }
    setIsEditDialogOpen(false);
    setEditingTheme(null);
  };

  const handleTagToDatabase = () => {
    console.log(`Tagging to database with new theme variable name: ${newVariableName}`);
    if (includeSentimentAnalysis) {
      console.log(`Tagging to database with new sentiment variable name: ${newSentimentVariableName}`);
    }
    console.log(`Include Sentiment Analysis: ${includeSentimentAnalysis}`);
    setIsTagging(true);
    setTaggingStatus('idle');
    setTaggingProgress(0);

    const totalRecords = 100;
    let currentRecord = 0;

    const processNextRecord = () => {
      if (currentRecord < totalRecords) {
        currentRecord++;
        setTaggingProgress(currentRecord);

        // Simulate processing time
        setTimeout(() => {
          processNextRecord();
        }, 50);
      } else {
        setIsTagging(false);
        
        // Alternate between success and error
        if (tagRunCount % 2 === 0) {
          setTaggingStatus('success');
        } else {
          setTaggingStatus('error');
        }
        
        // Increment the run count
        setTagRunCount(prevCount => prevCount + 1);
      }
    };

    processNextRecord();
  };

  // Modify the handleClearPreview function
  const handleClearPreview = () => {
    setPreviewGenerated(false);
    setPreviewedGridColumns([]);
    setPreviewedGridRows([]);
    setNewVariableName(''); // Add this line to clear the new variable name
  };

  const handlePreviewThemes = async () => {
    setIsLoading(true);
    try {
      console.log('Current gridRows:', gridRows);
      const dataToSend = gridRows.slice(0, 25); // Send only the first 25 rows for preview
      console.log('Data being sent to API:', {
        rowCount: dataToSend.length,
        sampleData: dataToSend.slice(0, 2),
        allColumns: dataToSend.length > 0 ? Object.keys(dataToSend[0]) : [],
      });

      if (!dataToSend.length) {
        throw new Error('No data available to analyze. Please ensure data is loaded first.');
      }

      const themesToSend = themes.map(theme => theme.text);
      const variableId = primaryVariable?.replace(/\s+/g, '_').toLowerCase() || 'default';

      const prompt = `
        You are analyzing text responses to categorize them into themes. Your task:

        1. For each row, carefully read the content in the Primary Analysis Variable: ${primaryVariable}
        2. Assign ONLY the most relevant theme(s) from this specific list: ${themesToSend.join(', ')}
        3. Each theme assignment must be justified by the actual text content
        4. If no theme clearly matches, use "Not interested / Answer not clear"
        5. Multiple themes can be assigned if clearly justified by the content (separate with commas)

        ${includeSentimentAnalysis ? 'Also analyze the sentiment (positive, neutral, or negative) based on the specific content.' : ''}

        IMPORTANT: Return ONLY a JSON array of objects. Do not include any explanations, Python code, or other text.
        Each object in the array should have all original columns plus:
        1. bayesprice_ai_theme_${variableId}: Theme assignments based on content match
        ${includeSentimentAnalysis ? `2. bayesprice_ai_sentiment_${variableId}: Sentiment based on content tone and language` : ''}
      `;

      console.log('Sending preview request with prompt:', prompt);

      const response = await axios({
        method: 'post',
        url: `${API_URL}/api/theme-preview`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        },
        data: {
          messages: [
            { role: 'system', content: 'You are an expert at analyzing and tagging data. Your task is to assign the most relevant themes to each row based on the content of the specified column.' },
            { role: 'user', content: `Here is the data to analyze:\n\n${JSON.stringify(dataToSend, null, 2)}` },
            { role: 'user', content: prompt }
          ]
        }
      });

      console.log('Received preview response:', response.data);

      if (response.data.choices && response.data.choices[0] && response.data.choices[0].message) {
        const content = response.data.choices[0].message.content;
        console.log('Raw content:', content);
        
        try {
          // Ensure the content is a properly closed array
          let cleanContent = content.trim();
          if (!cleanContent.endsWith(']')) {
            cleanContent += ']';
          }

          // Remove any potential problematic characters
          cleanContent = cleanContent
            .replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
            .replace(/\s+/g, ' ');

          console.log('Content to parse:', cleanContent);
          let previewData: any[] = JSON.parse(cleanContent);
          console.log('Parsed data:', previewData);

          // Validate the data
          if (!Array.isArray(previewData)) {
            throw new Error('Parsed data is not an array');
          }

          if (previewData.length === 0) {
            throw new Error('Parsed data array is empty');
          }

          // Add row IDs if they don't exist
          const dataWithIds = previewData.map((row, index) => ({
            id: row.id || index,
            ...row
          }));

          handlePreviewData(dataWithIds, variableId);
        } catch (error) {
          console.error('Error parsing JSON:', error);
          throw new Error(`Failed to parse preview data: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
      } else {
        throw new Error('Unexpected response structure from API');
      }
    } catch (error) {
      console.error('Error generating preview:', error);
      setDataError('Failed to generate preview. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Update the handlePreviewData function
  const handlePreviewData = (previewData: any[], variableId: string) => {
    console.log('Handling preview data:', previewData);

    // Get all unique keys from the preview data, excluding 'id'
    const allKeys = Array.from(new Set(previewData.flatMap(Object.keys)))
      .filter(key => key !== 'id');

    // Create columns with special handling for theme and sentiment columns
    const newColumns: GridColDef[] = allKeys.map(key => ({
      field: key,
      headerName: key === 'Please tell us why you are dissatisfied with NOW' ? 'Response' : 
                 key.includes('bayesprice_ai_theme') ? 'Theme' :
                 key.includes('bayesprice_ai_sentiment') ? 'Sentiment' : 
                 key,
      flex: 1,
      minWidth: key === 'Please tell us why you are dissatisfied with NOW' ? 400 : 200,
      // Special styling for theme and sentiment columns
      cellClassName: key.includes('bayesprice_ai_') ? 'theme-column' : undefined,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.value;
        if (key.includes('bayesprice_ai_')) {
          return (
            <Box sx={{ 
              backgroundColor: key.includes('_sentiment_') ? 
                value === 'positive' ? 'rgba(76, 175, 80, 0.1)' :
                value === 'negative' ? 'rgba(244, 67, 54, 0.1)' :
                'rgba(158, 158, 158, 0.1)' :
                'rgba(25, 118, 210, 0.08)',
              p: 1,
              borderRadius: 1,
              width: '100%'
            }}>
              {value}
            </Box>
          );
        }
        return value;
      }
    }));

    console.log('Created grid columns:', newColumns);
    setPreviewedGridColumns(newColumns);
    setPreviewedGridRows(previewData);
    setPreviewGenerated(true);
  };

  // Update the SuggestedPrompts component
  const SuggestedPrompts: React.FC<{ variable: string | null; onPromptClick: (prompt: string) => void; isDataLoaded: boolean }> = ({ variable, onPromptClick, isDataLoaded }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, width: '100%' }}>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => variable && onPromptClick(`Find a positive quote about ${variable}`)}
        sx={{ 
          fontSize: '0.75rem', 
          textTransform: 'none', 
          width: '49%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        disabled={!isDataLoaded || !variable}
      >
        Find a positive quote about {variable || 'variable'}
      </Button>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => variable && onPromptClick('Generate a list of 5 themes')}
        sx={{ 
          fontSize: '0.75rem', 
          textTransform: 'none', 
          width: '49%'
        }}
        disabled={!isDataLoaded || !variable}
      >
        Generate a list of 5 themes
      </Button>
    </Box>
  );

  // Add this function to handle clicking on a suggested prompt
  const handlePromptClick = (prompt: string) => {
    setChatInput(prompt);
  };

  // Add this new function to handle primary variable change
  const handlePrimaryVariableChange = (event: SelectChangeEvent<string>) => {
    setPrimaryVariable(event.target.value as string);
  };

  // Add a new function to handle the new variable name change
  const handleNewVariableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVariableName(event.target.value);
  };

  // Add this new function to handle the sentiment variable name change
  const handleNewSentimentVariableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSentimentVariableName(event.target.value);
  };

  // Add this useEffect to reset isDataLoaded when customer or job changes
  useEffect(() => {
    setIsDataLoaded(false);
    setHasVariables(false);
  }, [selectedCustomer, selectedJob]);

  useEffect(() => {
    console.log('Current job details:', {
      name: selectedJob,
      id: selectedJob?.id,
      customer: selectedCustomer
    });
  }, [selectedJob, selectedJob?.id, selectedCustomer]);

  // Add a useEffect to log state changes
  useEffect(() => {
    console.log('State updated:', {
      hasVariables,
      variablesField,
      selectedCustomer,
      selectedJob,
      isDataLoading
    });
  }, [hasVariables, variablesField, selectedCustomer, selectedJob, isDataLoading]);

  // Add this function to your component
  const loadDataIntoTable = (data: any) => {
    try {
      console.log('Raw data received:', data);
      
      if (!Array.isArray(data) || data.length === 0) {
        console.log('No data found or invalid format');
        setGridColumns([]);
        setGridRows([]);
        return;
      }

      // Get all unique variable names from the first object
      const variableNames = Object.keys(data[0]);
      
      // Create columns
      const columns: GridColDef[] = [
        {
          field: 'id',
          headerName: 'ID',
          width: 100,
        },
        ...variableNames.map(varName => ({
          field: varName,
          headerName: varName,
          width: varName.toLowerCase().includes('why') ? 600 : 150,
          flex: varName.toLowerCase().includes('why') ? 2 : 1,
          minWidth: varName.toLowerCase().includes('why') ? 400 : 100,
          renderCell: varName.toLowerCase().includes('why') ? (params: GridRenderCellParams) => {
            const value = params.value || '';
            return (
              <Tooltip title={value} placement="top-start">
                <Box sx={{ 
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }
                }}>
                  {value}
                </Box>
              </Tooltip>
            );
          } : undefined
        }))
      ];

      // Map the data to rows
      const rows = data.map((item: any, index: number) => ({
        id: index + 1,
        ...item
      }));

      console.log('Setting grid data:', { columns, rowCount: rows.length });
      setGridColumns(columns);
      setGridRows(rows);
      setIsDataLoaded(true);
    } catch (error) {
      console.error('Error loading data into table:', error);
      throw new Error('Failed to process data for display');
    }
  };

  // Add this useEffect near the top of your component
  useEffect(() => {
    console.log('Job selection updated:', {
      selectedJob,
      selectedJobId: selectedJob?.id,
      selectedCustomer
    });
  }, [selectedJob, selectedJob?.id, selectedCustomer]);

  // Add this near the top of your component, after the hooks
  useEffect(() => {
    console.log('Job context updated:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJob?.id
    });
  }, [selectedCustomer, selectedJob, selectedJob?.id]);

  // Modify the existing useEffect for job selection
  useEffect(() => {
    console.log('Job selection updated:', {
      selectedJob,
      selectedJobId: selectedJob?.id,
      selectedCustomer
    });
    
    // Log the current state of sessionInfo
    const sessionInfo = localStorage.getItem('sessionInfo');
    console.log('Current sessionInfo:', sessionInfo ? JSON.parse(sessionInfo) : 'Not found');
  }, [selectedJob, selectedJob?.id, selectedCustomer]);

  // Update this function to handle checkbox change
  const handleSentimentAnalysisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeSentimentAnalysis(event.target.checked);
  };

  // Add this effect to update the variable names when primaryVariable changes
  useEffect(() => {
    if (primaryVariable) {
      const variableId = primaryVariable.replace(/\s+/g, '_').toLowerCase();
      setNewVariableName(`bayesprice_ai_theme_${variableId}`);
      setNewSentimentVariableName(`bayesprice_ai_sentiment_${variableId}`);
    }
  }, [primaryVariable]);

  const handleStartClassification = async () => {
    setIsClassifying(true);
    try {
      const themeText = themes.map(theme => theme.text).join('\n');
      const varnamesArray = variablesField.split(',');
      
      await axios.post(`${API_URL}/api/start-classification`, {
        job_id: selectedJob?.id,
        varnames: varnamesArray,
        filter_: filterField.split(','),
        target_field: primaryVariable,
        theme_text: themeText,
        includeSentiment: includeSentimentAnalysis
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        }
      });

      // Move to step 3 immediately
      setActiveStep(2);
      
      // Start polling
      await fetchMiddlewareData();
      const interval = setInterval(fetchMiddlewareData, 3000);
      setPollingInterval(interval);

    } catch (error) {
      console.error('Error starting classification:', error);
      setDataError('Failed to start classification. Please try again.');
    } finally {
      setIsClassifying(false);
    }
  };

  const startPollingMiddlewareData = () => {
    // Clear any existing interval
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }

    // Start new polling interval
    const interval = setInterval(() => {
      fetchMiddlewareData();
    }, 3000); // Poll every 3 seconds

    setPollingInterval(interval);
  };

  const fetchMiddlewareData = async () => {
    if (!selectedJob?.id) return;
    
    setIsLoadingMiddlewareData(true);
    try {
      const response = await axios.get(`${API_URL}/api/check-interview-data`, {
        params: { job_id: selectedJob.id },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        }
      });
      
      setMiddlewareData([response.data]);
      
      // If processing is complete, stop polling
      if (response.data.isComplete) {
        if (pollingInterval) {
          clearInterval(pollingInterval);
          setPollingInterval(null);
        }
      }
    } catch (error) {
      console.error('Error fetching middleware data:', error);
      setMiddlewareDataError('Failed to fetch processing status.');
    } finally {
      setIsLoadingMiddlewareData(false);
    }
  };

  // Cleanup polling on unmount or when leaving the page
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  const handleImportVariables = async () => {
    // TODO: Implement the API call to import variables
    // For now, we'll simulate the process
    setTimeout(() => {
      setImportComplete(true);
    }, 2000);
  };

  const handleImportFromMiddleware = async (jobId: string) => {
    try {
      if (!carbonClient || !isAuthenticated) {
        throw new Error('Not authenticated or Carbon client not available');
      }

      if (!selectedCustomer || !selectedJob) {
        throw new Error('Please select a customer and job before importing');
      }

      // First, open the job
      await carbonClient.openCloudJob(selectedCustomer, selectedJob);

      // Now proceed with the partial import
      const importSettings: ImportSettings = {
        type: 'TSAPI',
        source: 'https://bayesprice.helix.ml/secret/api/',
        surveyId: jobId,
        link: 'case',
        overwrite: true,
        authorization: `${process.env.REACT_APP_BEARER_TOKEN}`
      };

      await carbonClient.importPartialJob(importSettings);
      
      // Show success message
      setImportComplete(true);
      
      // Refresh the middleware data
      await fetchMiddlewareData();
    } catch (error) {
      console.error('Error importing from middleware:', error);
      setMiddlewareDataError('Failed to import data. Please try again.');
    }
  };

  // Add an effect to handle polling when on step 3
  useEffect(() => {
    if (activeStep === 2) {
      // Initial fetch
      fetchMiddlewareData();
      
      // Start polling
      const interval = setInterval(fetchMiddlewareData, 3000);
      setPollingInterval(interval);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [activeStep]); // Only depend on activeStep

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        height: 'calc(100vh - 84px)',
        overflow: 'hidden',
        pl: 2,
        pr: 3,
        py: 2,
      }} 
      tabIndex={0}
      // Remove onKeyDown from here as we're using document-level event listener
    >
      {/* Left sidebar - Variable Tree */}
      <Paper sx={{ 
        width: 260, 
        p: 2, 
        mr: 2, // Right margin
        overflowY: 'auto', // Allow scrolling in the variable tree if needed
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>Variable Tree</Typography>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {isLoading ? (
            <Typography>Loading variable tree...</Typography>
          ) : errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : variableTree.length > 0 ? (
            <RichTreeView<ExtendedGenNode, true>
              items={variableTree}
              getItemLabel={(item) => item.label}
              slots={{
                item: (props: TreeItem2Props) => <CustomTreeItem key={props.itemId} {...props} />
              }}
              expandedItems={expandedVariableItems}
              selectedItems={selectedVariables}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect={true}
              sx={{
                '& .MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '& .MuiTreeItem-label': {
                  fontSize: '0.875rem',
                },
                '& .MuiTreeItem-group': {
                  marginLeft: 2,
                  paddingLeft: 1,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          ) : (
            <Typography>No variables found</Typography>
          )}
        </Box>
      </Paper>

      {/* Right side - Split view for DataGrid and Chat */}
      <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        {/* Data Grid Section - 70% */}
        <Box sx={{ flex: '0 0 70%', mr: 2, display: 'flex', flexDirection: 'column' }}>
          {/* Fields above the table */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2, pt: .2 }}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Variables"
                value={variablesField}
                onChange={handleVariablesFieldChange}
                size="small"
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 11px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, 'variables')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Filter"
                value={filterField}
                onChange={handleFilterFieldChange}
                size="small"
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 11px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, 'filter')}
              />
            </Grid>
            <Grid item xs={2}>
              <Button 
                variant="contained" 
                onClick={handleRefreshData}
                disabled={isDataLoading || !selectedCustomer || !selectedJob || !selectedJob.id || !hasVariables}
                startIcon={<RefreshIcon />}
              >
                {isDataLoading ? <CircularProgress size={24} /> : (isDataLoaded ? 'Refresh Data' : 'Load Data')}
              </Button>
            </Grid>
            {dataError && (
              <Grid item xs={12}>
                <Alert severity="error">{dataError}</Alert>
              </Grid>
            )}
          </Grid>

          {/* DataGrid */}
          <Paper sx={{ flex: 1, overflow: 'hidden' }}>
            <DataGridPro
              rows={previewGenerated ? previewedGridRows : gridRows}
              columns={previewGenerated ? previewedGridColumns : gridColumns}
              loading={isDataLoading || isLoading}
              pagination
              checkboxSelection
              disableRowSelectionOnClick
              getRowId={(row) => row[Object.keys(row)[0]]}  // Use the first column as the row id
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              sx={{
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                },
              }}
            />
          </Paper>
        </Box>

        {/* Chat Section - 30% */}
        <Box sx={{ flex: '0 0 30%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ pr: 2, pb: 2, pt: .2 }}> {/* Added padding to the right and bottom */}
            <StyledTabs 
              value={chatTab} 
              onChange={handleChatTabChange} 
              sx={{ 
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main',
                  height: '2px',
                },
                border: 1,
                borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                borderRadius: '4px',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <StyledTab label="Chat" value="chat" />
              <StyledTab label="Theme Tagger" value="themeTagger" />
            </StyledTabs>
          </Box>

          <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', pr: 2 }}> {/* Added padding to the right */}
            {chatTab === 'chat' && (
              <Box sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                overflow: 'hidden'
              }}>
                {/* Analysis Variable Section */}
                <Paper sx={{ 
                  p: 1, 
                  mb: 2, 
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                  border: 1,
                  borderColor: 'primary.main',
                  borderRadius: '4px',
                  zIndex: 1,
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                      Analysis Variable:
                    </Typography>
                    {isDataLoaded && allVariables.length > 0 ? (
                      <Select
                        value={primaryVariable || ''}
                        onChange={handlePrimaryVariableChange}
                        displayEmpty
                        variant="standard"
                        sx={{
                          minWidth: 120,
                          '&:before': { borderBottom: 'none' },
                          '&:after': { borderBottom: 'none' },
                          '& .MuiSelect-select': { py: 0 },
                        }}
                      >
                        {allVariables.map((variable, index) => (
                          <MenuItem key={index} value={variable}>{variable}</MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Typography variant="body2" fontWeight="medium">
                        {isDataLoaded ? 'No variables available' : 'Load data to select variable'}
                      </Typography>
                    )}
                  </Box>
                </Paper>

                {/* Chat History */}
                <Box sx={{ 
                  flex: 1, 
                  overflowY: 'auto', 
                  mb: 2, 
                  p: 2, 
                  border: '1px solid #ccc', 
                  borderRadius: 1, 
                  bgcolor: '#f9f9f9',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  {/* Chat messages */}
                  {chatHistory.map((chat, index) => (
                    <Box key={`${index}-${chat.content.substring(0, 20)}`} sx={{ mb: 1, textAlign: chat.role === 'user' ? 'right' : 'left' }}>
                      <Paper 
                        elevation={1}
                        sx={{ 
                          display: 'inline-block',
                          p: 1, 
                          maxWidth: '80%',
                          backgroundColor: chat.role === 'user' ? '#e3f2fd' : '#f0f0f0',
                          borderRadius: 2
                        }}
                      >
                        <MarkdownRenderer 
                          content={chat.content} 
                          setThemes={setThemes} 
                          setChatTab={setChatTab}
                        />
                      </Paper>
                    </Box>
                  ))}
                  {isChatLoading && <CircularProgress size={24} />}
                  {chatError && <Typography color="error">{chatError}</Typography>}
                  {showTagButton && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <MuiButton variant="contained" onClick={handleTagThemes}>
                        Tag
                      </MuiButton>
                    </Box>
                  )}
                </Box>

                {/* Suggested Prompts */}
                <SuggestedPrompts variable={primaryVariable} onPromptClick={handlePromptClick} isDataLoaded={isDataLoaded} />

                {/* Chat Input and Buttons */}
                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={isDataLoaded ? "Type your message here..." : "Load data to start chatting"}
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && isDataLoaded && handleChatSubmit()}
                    size="small"
                    disabled={!isDataLoaded}
                  />
                  <Button 
                    variant="contained" 
                    onClick={handleChatSubmit} 
                    sx={{ minWidth: 'auto', px: 2 }}
                    disabled={!isDataLoaded}
                  >
                    Chat
                  </Button>
                </Box>
              </Box>
            )}

            {chatTab === 'themeTagger' && (
              <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, bgcolor: '#f9f9f9', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Box sx={{ mb: 2 }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                      <StepLabel StepIconComponent={CustomStepIcon}>Refine Themes</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel StepIconComponent={CustomStepIcon}>AI Tagging</StepLabel>
                    </Step>
                    <Step completed={false}>
                      <StepLabel 
                        StepIconComponent={CustomStepIcon}
                      >
                        Import Tags
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                  {activeStep === 0 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      <TableContainer sx={{ maxHeight: 'calc(100% - 48px)', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: '70%' }}>Theme</TableCell>
                              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: '30%' }} align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {themes.map((theme, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ width: '70%' }}>{theme.text}</TableCell>
                                <TableCell sx={{ width: '30%' }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton onClick={() => handleEditTheme(theme)} size="small">
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteTheme(theme.id)} size="small">
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAddTheme}
                        sx={{ mt: 2 }}
                      >
                        Add New Theme
                      </Button>
                      
                      {/* Replace the RadioGroup with a single Checkbox */}
                      <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeSentimentAnalysis}
                              onChange={handleSentimentAnalysisChange}
                              name="sentimentAnalysis"
                            />
                          }
                          label="Include Sentiment Analysis"
                        />
                      </Box>
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="h6" gutterBottom>
                        Tag Themes to Database
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '300px', gap: 2 }}>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={previewGenerated ? handleClearPreview : handlePreviewThemes}
                          disabled={isClassifying}
                          fullWidth
                        >
                          {isLoading ? <CircularProgress size={24} /> : (previewGenerated ? 'Clear Preview' : 'Preview Themes')}
                        </Button>
                        {previewGenerated && (
                          <>
                            <TextField
                              label="New Theme Variable Name"
                              value={newVariableName}
                              onChange={handleNewVariableNameChange}
                              disabled={isClassifying || classificationComplete}
                              fullWidth
                            />
                            {includeSentimentAnalysis && (
                              <TextField
                                label="New Sentiment Variable Name"
                                value={newSentimentVariableName}
                                onChange={handleNewSentimentVariableNameChange}
                                disabled={isClassifying || classificationComplete}
                                fullWidth
                              />
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleStartClassification}
                              disabled={isClassifying || classificationComplete || !newVariableName.trim() || (includeSentimentAnalysis && !newSentimentVariableName.trim())}
                              fullWidth
                            >
                              {isClassifying ? <CircularProgress size={24} /> : 'Start Classification'}
                            </Button>
                            {classificationComplete && (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleImportVariables}
                                disabled={importComplete}
                                fullWidth
                              >
                                {importComplete ? 'Variables Imported' : 'Import Variables'}
                              </Button>
                            )}
                            {classificationComplete && (
                              <Alert severity="success">
                                Classification {includeSentimentAnalysis ? 'and Sentiment Analysis ' : ''}completed successfully!
                              </Alert>
                            )}
                            {importComplete && (
                              <Alert severity="success">
                                Variables imported successfully!
                              </Alert>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                  {activeStep === 2 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      <Typography variant="h6" gutterBottom>
                        Import Tagged Data to Carbon
                      </Typography>
                      {isLoadingMiddlewareData && middlewareData.length === 0 ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                          <CircularProgress />
                          <Typography>Checking classification progress...</Typography>
                        </Box>
                      ) : middlewareDataError ? (
                        <Typography color="error">{middlewareDataError}</Typography>
                      ) : middlewareData.length === 0 ? (
                        <Typography>No tagged data available yet. Classification is in progress...</Typography>
                      ) : (
                        <List>
                          <ListItem key="classification-progress">
                            <ListItemText 
                              primary="Classification Progress" 
                              secondary={
                                <Box component="div">
                                  <ClassificationProgress data={middlewareData[0]} />
                                </Box>
                              }
                            />
                            {middlewareData[0].recordCount > 0 && (
                              <ListItemSecondaryAction>
                                <Button
                                  variant="contained"
                                  onClick={() => handleImportFromMiddleware(selectedJob?.id || '')}
                                  disabled={!middlewareData[0].isComplete}
                                >
                                  {middlewareData[0].isComplete ? 'Import' : 'Processing...'}
                                </Button>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        </List>
                      )}
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === 2 ? (
                    <Button onClick={handleReset}>
                      Reset
                    </Button>
                  ) : (
                    <Button onClick={handleNext}>
                      {activeStep === 1 && !classificationComplete ? 'Skip to Import' : 'Next'}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Edit Theme Dialog */}
      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
        <DialogTitle>{editingTheme?.id ? 'Edit Theme' : 'Add New Theme'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Theme"
            fullWidth
            value={editingTheme?.text || ''}
            onChange={(e) => setEditingTheme(prev => prev ? {...prev, text: e.target.value} : null)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveTheme}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};